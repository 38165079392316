import { ActionTree } from "vuex";
import { ExtensionGroupsState } from "./types";
import { RootState } from "../types";
import Axios from "axios";
import { CreateExtensionGroupModel } from "@/models/CreateExtensionGroupModel";
import { RenameExtensionGroupModel } from "@/models/RenameExtensionGroupModel";
import { Guid } from "guid-typescript";
import { UpdateExtensionGroupThreadCountModel } from "@/models/UpdateExtensionGroupThreadCountModel";
import { UpdateExtensionGroupModel } from "@/models/UpdateExtensionGroupModel";
import { ExtensionGroup } from "@/models/ExtensionGroup";
import { Account } from "@/models/Account";
import { UpdateExtensionGroupColumnSettingsModel } from "@/models/UpdateExtensionGroupColumnSettingsModel";
import { UpdateExtensionGroupFullModel } from "@/models/UpdateExtensionGroupFullModel";
import { ExtensionGroupProxiesModel } from "@/models/ExtensionGroupProxiesModel";
import proxy from "../proxy";

export const actions: ActionTree<ExtensionGroupsState, RootState> = {
  createExtensionGroup(
    { commit }: any,
    model: CreateExtensionGroupModel
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post("/api/extension-groups", model)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  fetchExtensionGroups({ commit }: any): Promise<ExtensionGroup[]> {
    return new Promise((resolve, reject) => {
      Axios.get("/api/extension-groups", { params: { pageSize: 100 } })
        .then(response => {
          const extensionGroups: ExtensionGroup[] = response.data.data;

          commit("setExtensionGroups", extensionGroups);

          resolve(extensionGroups);
        })
        .catch(error => reject(error.response.data));
    });
  },
  addToExtensionGroup(
    { commit }: any,
    model: UpdateExtensionGroupModel
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extension-groups/${model.id}/add`, model)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  removeFromExtensionGroup(
    { commit }: any,
    model: UpdateExtensionGroupModel
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extension-groups/${model.id}/remove`, model)
        .then(response => {
          if (!response.data.data.extensions.length) {
            commit("setSelectedExtensionGroup", null);
          }
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  deleteExtensionGroup({ commit }: any, id: Guid): Promise<Account> {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/extension-groups/${id}`)
        .then(response => {
          commit("setSelectedExtensionGroup", null);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },

  renameExtensionGroup(
    { commit }: any,
    model: RenameExtensionGroupModel
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.patch(`/api/extension-groups/${model.extensionGroupId}`, model)
        .then(response => {
          commit("renameExtensionGroup", {
            id: model.extensionGroupId,
            name: response.data.data.name
          });
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },

  updateExtensionGroupThreadCount(
    { commit }: any,
    model: UpdateExtensionGroupThreadCountModel
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.patch(`/api/extension-groups/${model.extensionGroupId}`, model)
        .then(response => {
          commit("updateExtensionGroupThreadCount", {
            id: model.extensionGroupId,
            threadCount: response.data.data.threadCount
          });
          commit(
            "updateSelectedExtensionGroupThreadCount",
            response.data.data.threadCount
          );
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },

  updateExtensionGroupColumnSettings(
    { commit }: any,
    model: UpdateExtensionGroupColumnSettingsModel
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.patch(`/api/extension-groups/${model.extensionGroupId}`, model)
        .then(response => {
          commit("updateExtensionGroupColumnSettings", {
            id: model.extensionGroupId,
            columnSettings: response.data.data.columnSettings
          });
          commit(
            "updateSelectedExtensionGroupColumnSettings",
            response.data.data.columnSettings
          );
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },

  updateExtensionGroup(
    { commit }: any,
    model: UpdateExtensionGroupFullModel
  ):Promise<void>{
    return new Promise((resolve, reject) => {
      Axios.patch(`/api/extension-groups/${model.id}`, model)
        .then(response => {

          //update column settings
          commit("updateExtensionGroupColumnSettings", {
            id: model.id,
            columnSettings: response.data.data.columnSettings
          });
          commit(
            "updateSelectedExtensionGroupColumnSettings",
            response.data.data.columnSettings
          );
          
          //update thread count
          commit("updateExtensionGroupThreadCount", {
            id: model.id,
            threadCount: response.data.data.threadCount
          });

          commit("updateSelectedExtensionGroupThreadCount", model.threadCount);
          
          //rename group extensions
          commit("renameExtensionGroup", {
            id: model.id,
            name: response.data.data.name
          });

          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },

  addProxiesToExtensionGroup({commit}: any, model: ExtensionGroupProxiesModel): Promise<void>{
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/addproxy`,
      {
        extensionGroupIds: model.extensionGroupIds,
        extensionIds: [],
        proxyIds: model.proxies.map(proxy => proxy.id)
      }).then(response => {
        commit('addProxiesToExtensionGroup',model)
        resolve(response.data)
      }).catch(e => reject(e))
    })
  },
};