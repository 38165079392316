import { ActionTree } from "vuex";
import { SettingsState } from "./types";
import { RootState } from "../types";
import Axios from "axios";
import { GithubTokenRequest } from "@/models/Settings/Github/GithubTokenRequest";
import { GithubTokenResponse } from "@/models/Settings/Github/GithubTokenResponse";
import {
  GithubRequest,
  GithubDeleteRequest,
  GitHubUploadFileRequest,
  GitHubGetLinkRequest,
  GitHubSaveCredentialsFileRequest
} from "@/models/Settings/Github/GithubRequest";
import {
  GitHubResponse,
  Status
} from "@/models/Settings/Github/GitHubResponse";
import { GithubTree } from "@/models/Settings/Github/GitHubTree";
import { GenerateExtensionArchiveModel } from "@/models/Settings/ExtensionArchive";
import { ErrorCode } from "@/models/enums/ErrorCode";

export const actions: ActionTree<SettingsState, RootState> = {
  connectGithub({ commit }, data: GithubTokenRequest) {
    return new Promise<boolean>(resolve => {
      Axios.post<GitHubResponse<GithubTokenResponse>>(
        "/api/github/connect",
        data
      )
        .then(response => {
          if (response.data.isSuccess) {
            const token = response.data.result.token;
            const username = response.data.result.username;

            localStorage.setItem("github-token", token);
            localStorage.setItem("github-username", username);

            commit("connectGithubSuccess", response.data.result);

            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => resolve(false));
    });
  },

  disconnectGithub({ commit, state }) {
    const data: GithubRequest = {
      token: state.token,
      username: state.githubUsername
    };

    localStorage.removeItem("github-token");
    localStorage.removeItem("github-username");

    return new Promise<void>(resolve => {
      Axios.post("/api/github/disconnect", data)
        .then(() => {
          commit("removeToken");
          resolve();
        })
        .catch(() => {
          commit("removeToken");
          resolve();
        });
    });
  },

  initRepo({ state }) {
    const data: GithubRequest = {
      token: state.token,
      username: state.githubUsername
    };

    return new Promise<Status>(resolve => {
      Axios.post<GitHubResponse<any>>("/api/github/initialize", data)
        .then(response => resolve(response.data.status))
        .catch(() => resolve(Status.Failure));
    });
  },

  initConfig({ state }) {
    const data: GithubRequest = {
      token: state.token,
      username: state.githubUsername
    };

    return new Promise<boolean>(resolve => {
      Axios.post<GitHubResponse<any>>(
        "/api/github/force-initialize-config",
        data
      )
        .then(response => resolve(response.data.isSuccess))
        .catch(() => resolve(false));
    });
  },

  getRepoTree({ state }, forceReload: boolean) {
    const data: GithubRequest = {
      token: state.token,
      username: state.githubUsername
    };

    return new Promise<GithubTree | undefined>(resolve => {
      Axios.post<GitHubResponse<GithubTree>>(
        `/api/github/tree?forceReload=${forceReload}`,
        data
      )
        .then(response => {
          if (response.data.isSuccess) {
            resolve(response.data.result);
          } else {
            resolve(undefined);
          }
        })
        .catch(() => resolve(undefined));
    });
  },

  deleteFile({ state }, file: GithubTree) {
    const data: GithubDeleteRequest = {
      token: state.token,
      username: state.githubUsername,
      sha: file.sha,
      fullPath: file.fullPath
    };

    return new Promise<boolean>(resolve => {
      Axios.post<GitHubResponse<any>>("/api/github/delete-file", data)
        .then(response => resolve(response.data.isSuccess))
        .catch(() => resolve(false));
    });
  },

  deleteFolder({ state }, data: GithubDeleteRequest) {
    data.token = state.token;
    data.username = state.githubUsername;

    return new Promise<boolean>(resolve => {
      Axios.post<GitHubResponse<any>>("/api/github/delete-folder", data)
        .then(response => resolve(response.data.isSuccess))
        .catch(() => resolve(false));
    });
  },

  uploadFile({ state }, data: GitHubUploadFileRequest) {
    data.token = state.token;
    data.username = state.githubUsername;

    const formData = new FormData();
    formData.append("file", data.file as File);
    data.file = null;
    formData.append("request", JSON.stringify(data));

    return new Promise<GitHubResponse<GithubTree> | undefined>(resolve => {
      Axios.post<GitHubResponse<GithubTree>>("/api/github/upload", formData)
        .then(response => resolve(response.data))
        .catch(() => resolve(undefined));
    });
  },

  getExternalLink({ state }, path: string) {
    const data: GitHubGetLinkRequest = {
      token: state.token,
      username: state.githubUsername,
      path: path
    };

    return new Promise<string | null>(resolve => {
      Axios.post<GitHubResponse<string>>("/api/github/external-link", data)
        .then(response => resolve(response.data.result))
        .catch(() => resolve(null));
    });
  },

  saveCredentialsFileToGithub({ state }, credentials: string) {
    const data: GitHubSaveCredentialsFileRequest = {
      token: state.token,
      username: state.githubUsername,
      credentials
    };

    return new Promise<void>((resolve, reject) => {
      Axios.post<GitHubResponse<void>>(
        "/api/extension-settings/github/credentials-file",
        data
      )
        .then(response => {
          if (!response.data.isSuccess) {
            throw ErrorCode.UnknownError;
          }
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  fetchExternalToken() {
    return new Promise<string>((resolve, reject) => {
      Axios.get("/getexternaltoken")
        .then(response => resolve(response.data.data.externalToken))
        .catch(error => reject(error.response.data));
    });
  },

  generateExtensionArchive(_, model: GenerateExtensionArchiveModel) {
    return new Promise<Blob>((resolve, reject) => {
      Axios.post("/api/extension-settings/archive", model, {
        responseType: "blob"
      })
        .then(response => resolve(new Blob([response.data])))
        .catch(error => reject(error));
    });
  },

  getExtensionCredentialsJson() {
    return new Promise<object>((resolve, reject) => {
      Axios.get("/api/extension-settings/default-credentials")
        .then(response => JSON.parse(response.data.data))
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
};
