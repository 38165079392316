import { GetterTree } from "vuex";
import { ScriptsState } from "./types";
import { RootState } from "../types";
import { Guid } from "guid-typescript";
import { SocialNetwork } from "@/models/enums/SocialNetwork";
import { Script } from "@/models/Script";
import { ActionType } from "@/models/ActionType";

export const scriptsBySocialNetwork = (allScripts: Script[], socialNetwork: SocialNetwork) => {
  return allScripts.filter((script: Script) => {
    const actionsSocialNetworks = new Set<SocialNetwork>();

    for (const { actionType } of script.scriptActions) {
      if (actionType?.socialNetwork != null) {
        actionsSocialNetworks.add(actionType.socialNetwork);
      }
    }

    // If sript contains only actions with common action type
    if (actionsSocialNetworks.size == 0) return true;

    return actionsSocialNetworks.has(socialNetwork);
  });
};

export const getters: GetterTree<ScriptsState, RootState> = {
  allScripts: (state: ScriptsState) => state.scripts,
  scriptsBySocialNetwork: (state: ScriptsState, getters: any) => {
    return scriptsBySocialNetwork(getters.allScripts, getters.selectedScriptSocialNetwork);
  },
  scriptActionTypesBySocialNetwork: (state: ScriptsState, getters: any) =>
    getters.actionTypes.filter((a: ActionType) => a.socialNetwork === null || a.socialNetwork === getters.selectedScriptSocialNetwork),
  // TODO: move to action (since we need to fetch it from server)
  getScriptById: (state: ScriptsState) => (id: Guid) =>
    state.scripts.find(s => s.id === id),
  selectedScriptSocialNetwork: (state: ScriptsState) => state.selectedScriptSocialNetwork,
  allActionRows: (state: ScriptsState) => state.actionRows,
  selectedScript: (state: ScriptsState) => state.selectedScript
};
