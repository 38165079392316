import { ActionTree } from "vuex";
import { DocumentsState } from "./types";
import { RootState } from "../types";
import { Document } from "@/models/Document";
import { GetDocumentsModel } from "@/models/GetDocumentsModel";
import Axios from "axios";
import { ErrorResponse } from "@/models/ErrorResponse";
import { Guid } from "guid-typescript";

export const actions: ActionTree<DocumentsState, RootState> = {
  fetchDocuments({ commit }: any, model: GetDocumentsModel) {
    return new Promise((resolve, reject) => {
      Axios.get("/api/documents", { params: model })
        .then(response => {
          const documents: Document[] = response.data.data;
          const totalCount: number = response.data.totalCount;

          commit("setDocuments", documents);
          commit("setDocumentsTotalCount", totalCount);

          resolve(documents);
        })
        .catch(error => reject(error.response.data));
    });
  },
  uploadDocument({ commit }: any, file: File) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("document", file);

      Axios.post("/api/documents", formData)
        .then(response => {
          commit("addDocument", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  async uploadDocuments({ commit }: any, documents: Document[]) {
    for (const document of documents)
      await this.dispatch("uploadDocument", document);
  },
  deleteDocument({ commit }: any, id: Guid) {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/documents/${id}`)
        .then(response => {
          commit("removeDocument", id);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  }
};
