import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { ActionsState } from "./types";
import { RootState } from "../types";
import { SocialNetwork } from "@/models/enums/SocialNetwork";

export const state: ActionsState = {
  actions: [],
  actionsByType: [],
  selectedSocialNetwork: null,
  selectedActionType: null,
  selectedAction: null,
  actionRows: [],
  actionTypes: [],
  actionOptions: [],
  actionOptionValues: {}
};

const namespaced = false;

export const documents: Module<ActionsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default { state, getters, actions, mutations };
