export enum ActionSettingType {
  Text = "text",
  ComboBox = "combo",
  List = "list",
  Select = "select",
  Range = "range",
  Random = "random",
  File = "file",
  Checkbox = "checkbox"
}
