import Vue from "vue";
import VueI18n from "vue-i18n";

import { Locales } from "./types";

import en from "./locales/en.json";
import ru from "./locales/ru.json";
import pt from "./locales/pt.json";

const localizationStrings = {
  [Locales.EN]: en,
  [Locales.RU]: ru,
  [Locales.PT]: pt,
};

Vue.use(VueI18n);

export default new VueI18n({
  locale: Locales.RU,
  fallbackLocale: Locales.EN,
  messages: localizationStrings
});
