<template>
  <v-dialog v-model="showModal" :max-width="700" @click:outside="setShowModal(false)">
    <v-card>
      <v-card-title class="headline">
        {{ $t("scripts.get-scripts-modal.title") }}
      </v-card-title>
      <v-container class="py-0">
        <v-row v-if="errorMessage">
          <v-card-text class="error-message">
            {{ errorMessage }}
          </v-card-text>
        </v-row>
        <v-row>
          <v-col cols="3" class="text_self-align">
            {{ $t("scripts.get-scripts-modal.sender") }}:
          </v-col>
          <v-col class="text_self-align">
            <b>{{ sender }}</b>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="text_self-align">
            {{ $t("scripts.get-scripts-modal.scripts") }}:
          </v-col>
          <v-col>
            <v-select multiple :menu-props="{ maxHeight: '200' }" persistent-hint
              v-model="selectedScripts" :items="scripts" :label="$t('scripts.get-scripts-modal.select-scripts')"
              item-text="name" :item-value="item => item" />
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions style="margin-top: 10px;">
        <v-spacer />
        <v-btn color="red" :loading="loading" :disabled="isSubmitDisabled" @click="tryHandleScripts(false)">
          {{ $t("scripts.get-scripts-modal.reject") }}
        </v-btn>
        <v-spacer style="max-width: 40px" />
        <v-btn color="primary darken-1" :loading="loading" :disabled="isSubmitDisabled" @click="tryHandleScripts(true)">
          {{ $t("scripts.get-scripts-modal.accept") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.text_self-align {
  align-self: center;
}

.error-message {
  color: red;
  text-align: center;
}
</style>

<script lang="ts">
import { Script } from "@/models/Script";
import { GetScripts } from "@/models/ScriptTransfering/GetScripts";
import { HandleGettingScripts } from "@/models/ScriptTransfering/HandleGettingScripts";
import { Component, Emit, PropSync, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component
export default class GetScriptsModal extends Vue {
  private loading = false;
  private errorMessage: string | null = null;

  private sender = "";
  private scripts: Script[] = [];

  private selectedScripts: Script[] = [];

  @PropSync("show", { required: true })
  private showModal!: boolean;

  @Emit("setShowModal")
  private setShowModal(value: boolean) {
    return value;
  }

  @Action("getScripts")
  private getScripts!: () => Promise<GetScripts>;

  @Action("acceptScripts")
  private acceptScripts!: (data: HandleGettingScripts) => Promise<any>;

  @Action("rejectScripts")
  private rejectScripts!: (data: HandleGettingScripts) => Promise<any>;

  @Watch("scripts", { immediate: false })
  private onScriptsChange(scripts: Script[]) {
    if (scripts.length == 0) {
      this.setShowModal(false);
    }
  }

  @Watch("showModal", { immediate: false })
  private async onShowModalChange(showModal: boolean) {
    if (showModal) {
      this.loading = true;

      try {
        const response = await this.getScripts();

        if (response.scripts.length > 0 && response.senderEmail) {
          this.sender = response.senderEmail;
          this.scripts = response.scripts;
        } else {
          this.errorMessage = this.$tc("scripts.get-scripts-modal.error.not-found");
        }
      } catch {
        this.errorMessage = this.$tc("scripts.get-scripts-modal.error.unexpected");
      }

      this.loading = false;
    }
  }

  private get isSubmitDisabled(): boolean {
    return this.sender.length == 0 || this.selectedScripts.length == 0 || !!this.errorMessage;
  }

  private async tryHandleScripts(isAccepted: boolean) {
    this.errorMessage = null;
    this.loading = true;

    const data: HandleGettingScripts = {
      scriptIds: this.selectedScripts.map(script => script.id),
      senderEmail: this.sender
    };

    try {
      const response = isAccepted
        ? await this.acceptScripts(data)
        : await this.rejectScripts(data);

      if (response.data) {
        this.scripts = this.scripts.filter(script => !this.selectedScripts.includes(script));
      } else {
        this.errorMessage = response.message;
      }
    } catch (err) {
      this.errorMessage = this.$tc("scripts.get-scripts-modal.error.unexpected");
    }

    this.loading = false;
  }
}
</script>
