import { GetterTree } from "vuex";
import { BrowserProfilesState } from "./types";
import { RootState } from "../types";
import {
  BptNameListItem,
  BrowserProfileTemplate
} from "@/models/BrowserProfile";

export const getters: GetterTree<BrowserProfilesState, RootState> = {
  browserProfileTemplates: state => state.templates,
  getBrowserProfileTemplateById: state => (id: BrowserProfileTemplate["id"]) =>
    state.templates.find(template => template.id === id),
  browserProfileDefaultOptions: state => state.defaultOptions,
  isDefaultOptionsLoading: state => state.isDefaultOptionsLoading,
  browserProfileNameList: state =>
    state.templates.map(
      (template: BrowserProfileTemplate): BptNameListItem => {
        return {
          text: template.name,
          value: template.id
        };
      }
    ),
  adsPowerGroups: state => state.adsGroups
};
