import { GetterTree } from "vuex";
import { SettingsState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<SettingsState, RootState> = {
  githubClientId: (state: SettingsState) => state.githubClientId,
  githubRedirectUrl: (state: SettingsState) => state.githubRedirectUrl,
  githubUsername: (state: SettingsState) => state.githubUsername,
  isGithubConnected: (state: SettingsState) => !!state.token,
  allowedUploadTotalSize: (state: SettingsState) =>
    state.allowedUploadTotalSize,
  githubRepo: (state: SettingsState) => state.githubRepo,
  githubToken: state => state.token
};
