import { MutationTree } from "vuex";
import { DocumentsState } from "./types";
import { Document } from "@/models/Document";
import { Guid } from "guid-typescript";

export const mutations: MutationTree<DocumentsState> = {
  setDocuments: (state: DocumentsState, documents: Document[]) =>
    (state.documents = documents),
  addDocument: (state: DocumentsState, document: Document) =>
    state.documents.push(document),
  removeDocument: (state: DocumentsState, id: Guid) => {
    const index: number = state.documents.findIndex(d => d.id === id);
    if (index !== -1) {
      state.documents.splice(index, 1);
    }
  },
  setDocumentsTotalCount: (state: DocumentsState, totalCount: number) =>
    (state.totalCount = totalCount)
};
