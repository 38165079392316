import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { ErrorState } from "./types";

export const actions: ActionTree<ErrorState, RootState> = {
  showError({ commit }, payload) {
    let errorCode: number;
    let errorType: ErrorState["errorType"] = "errors";
    const fallbackErrorMessage =
      payload?.response?.data?.msg ||
      payload?.response?.data?.message ||
      payload.response?.data?.title ||
      "";

    if (typeof payload === "number") {
      errorCode = payload;
    } else if (payload?.errorCode) {
      errorCode = payload?.errorCode;
    } else if (payload?.response?.data?.errorCode) {
      errorCode = payload?.response?.data?.errorCode;
    } else if (payload?.response?.status) {
      errorCode = payload.response.status;
      errorType = "http-errors";
    } else {
      errorCode = 1;
    }

    commit("setErrorMessage", {
      errorType,
      errorCode
    });
    commit("setFallbackErrorMessage", fallbackErrorMessage);
    commit("setIsErrorOccured", true);
  },

  resetError: ({ commit }) => {
    commit("setErrorMessage", {
      errorType: "errors",
      errorCode: 0
    });
    commit("setIsErrorOccured", false);
    commit("setFallbackErrorMessage", "");
  }
};
