import { ActionTree } from "vuex";
import { ScriptsState } from "./types";
import { RootState } from "../types";
import { Script } from "@/models/Script";
import Axios from "axios";
import { CreateScriptModel } from "@/models/CreateScriptModel";
import { UpdateScriptModel } from "@/models/UpdateScriptModel";
import { Guid } from "guid-typescript";
import { SendScripts } from "@/models/ScriptTransfering/SendScripts";
import { HandleGettingScripts } from "@/models/ScriptTransfering/HandleGettingScripts";

export const actions: ActionTree<ScriptsState, RootState> = {
  fetchScripts({ commit }: any) {
    return new Promise((resolve, reject) => {
      Axios.get("/api/scripts")
        .then(response => {
          const scripts: Script[] = response.data.data;

          commit("setScripts", scripts);
          resolve(scripts);
        })
        .catch(error => reject(error.response.data));
    });
  },
  createScript({ commit }: any, model: CreateScriptModel) {
    return new Promise((resolve, reject) => {
      Axios.post("/api/scripts", model)
        .then(response => {
          commit("addScript", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  updateScript({ commit }: any, model: UpdateScriptModel) {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/scripts/${model.id}`, model.data)
        .then(response => {
          commit("updateScript", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  deleteScript({ commit }: any, id: Guid) {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/scripts/${id}`)
        .then(response => {
          commit("removeScript", id);
          commit("setSelectedScript", null);
          commit("removeAllActionRows");
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  getScriptById({ commit }: any, id: Guid) {
    return new Promise((resolve, reject) => {
      Axios.get(`/api/scripts/${id}`)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  sendScripts({ commit }: any, data: SendScripts) {
    return new Promise((resolve, reject) => {
      Axios.post("/api/scripts/send", data)
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    });
  },
  getScripts({ commit }: any) {
    return new Promise((resolve, reject) => {
      Axios.get("/api/scripts/sent")
        .then(response => resolve(response.data.data))
        .catch(error => reject(error.response.data));
    });
  },
  getScriptsCount({ commit }: any) {
    return new Promise<number>(resolve => {
      Axios.get("/api/scripts/sent/count")
        .then(response => resolve(response.data.data))
        .catch(() => resolve(0));
    });
  },
  acceptScripts({ commit }: any, data: HandleGettingScripts) {
    return new Promise((resolve, reject) => {
      Axios.post("/api/scripts/sent/accept", data)
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    });
  },
  rejectScripts({ commit }: any, data: HandleGettingScripts) {
    return new Promise((resolve, reject) => {
      Axios.post("/api/scripts/sent/reject", data)
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    });
  }
};
