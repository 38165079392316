import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "../types";
import { TariffsState } from "./types";

export const state: TariffsState = {
  tariffs: [],
  currentTariff: null,
  selectedTariff: null
};

const namespaced = false;

export const documents: Module<TariffsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default { state, getters, actions, mutations };
