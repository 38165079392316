import { ActionTree } from "vuex";
import { ScheduleState } from "@/store/schedule/types";
import { RootState } from "@/store/types";
import Axios from "axios";

export const actions: ActionTree<ScheduleState, RootState> = {
  updateSchedule({ commit }: any, payload: ScheduleState): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post("/api/schedules/update", payload)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  }
};