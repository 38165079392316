export enum AuthStatus {
  Error = -1,
  None,
  Loading,
  Success
}

export interface AuthState {
  accessToken: string;
  status: AuthStatus;
}
