<template>
  <v-dialog v-model="showModal" max-width="600" @click:outside="onClose">
    <v-card>
      <v-card-title class="headline">
        {{ $t("actions.nested-action.updates") }}
      </v-card-title>
      <v-container class="py-0">
        <v-row v-for="update in actionUpdates" :key="update.id" class="update">
          <v-spacer style="max-width: 20px;" />
          <v-icon :color="getColor(update)">
            {{ getIcon(update) }}
          </v-icon>
          <v-spacer style="max-width: 10px;" />
          <v-col v-html="getText(update)"></v-col>
        </v-row>
      </v-container>
      <v-card-actions style="margin-top: 10px;">
        <v-spacer />
        <v-btn color="primary darken-2" @click="onClose()">
          {{ $t("actions.nested-action.ok") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.update {
  border: 1px;
  padding-top: 0;
  margin-top: -12px;
}
</style>

<script lang="ts">
import { ActionUpdate } from "@/models/Action";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component
export default class ScriptActionUpdateNotifier extends Vue {
  private showModal = false;
  private actionUpdates: ActionUpdate[] = [];

  @Action("getNestedActionUpdates")
  private getNestedActionUpdates!: () => Promise<ActionUpdate[]>;

  @Action("markNestedActionUpdatesNotified")
  private markNestedActionUpdatesNotified!: (ids: number[]) => Promise<void>;

  private async mounted() {
    try {
      this.actionUpdates = await this.getNestedActionUpdates();
    } finally {
      if (this.actionUpdates.length > 0) {
        this.showModal = true;
      }
    }
  }

  private async onClose() {
    this.showModal = false;
    try {
      const ids = this.actionUpdates.map(a => a.id);
      await this.markNestedActionUpdatesNotified(ids);
    } finally {
      this.actionUpdates = [];
    }
  }

  private getIcon(update: ActionUpdate) {
    switch (update.type) {
      case "UpdateSent":
        return "mdi-update";
      case "UpdateReceived":
        return "mdi-checkbox-marked-circle";
      case "Deleted":
        return "mdi-delete-circle";
    }
  }

  private getColor(update: ActionUpdate) {
    switch (update.type) {
      case "UpdateSent":
        return "blue";
      case "UpdateReceived":
        return "green";
      case "Deleted":
        return "red";
    }
  }

  private getText(update: ActionUpdate) {
    switch (update.type) {
      case "UpdateSent":
        return this.$tc("actions.nested-action.update-sent") +
          ` ${update.actionName}.<br />` +
          this.$tc("actions.nested-action.version") +
          `: ${update.actionVersion}; ` +
          this.$tc("actions.nested-action.update-note") +
          `: <i>${update.note}</i>`;
      case "UpdateReceived":
        return `${update.actionName} ` +
          this.$tc("actions.nested-action.update-recieved") +
          `.<br />` +
          this.$tc("actions.nested-action.version") +
          `: ${update.actionVersion}; ` +
          this.$tc("actions.nested-action.update-note") +
          `: <i>${update.note}</i>`;
      case "Deleted":
        return `${update.note} ` + this.$tc("actions.nested-action.deleted");
    }
  }
}
</script>
