import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import { default as i18n } from "@/i18n";
import extensions from "./extensions";
import extensionGroups from "./extension-groups";
import scripts from "./scripts";
import actions from "./actions";
import documents from "./documents";
import auth from "./auth";
import profile from "./profile";
import preferences from "./preferences";
import tariffs from "./tariffs";
import payments from "./payments";
import schedule from "./schedule";
import proxy from "./proxy";
import error from "./error";
import settings from "./settings";
import { browserConfigTemplates } from "./browser-config-templates";
import { browserProfiles } from "./browser-profiles";
import { settingsHashRoutes } from "@/constants";
import { extensionData } from "./extension-data";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    appName: "Scenum",
    packageVersion: process.env.PACKAGE_VERSION || "0",
    layout: "default-layout",
    tableOverflow: 0,
    apiUrl: process.env.VUE_APP_API_URL || "http://localhost:5000",
    topNavigationItems: () => [
      {
        icon: "mdi-puzzle",
        text: i18n.tc("common.extensions"),
        route: "/extensions"
      },
      {
        icon: "mdi-playlist-check",
        text: i18n.tc("common.scripts"),
        route: "/scripts"
      },
      {
        icon: "mdi-playlist-play",
        text: i18n.tc("common.actions"),
        route: "/actions"
      },
      {
        icon: "mdi-file-document-outline",
        text: i18n.tc("common.documents"),
        route: "/documents"
      },
      {
        icon: "mdi-cloud-outline",
        text: i18n.tc("common.proxy"),
        route: "/proxy"
      }
    ],
    bottomNavigationItems: () => [
      {
        icon: "mdi-cog",
        text: i18n.tc("common.settings"),
        route: "/settings",
        subLinks: [
          {
            to: `/settings#${settingsHashRoutes.integration}`,
            icon: "mdi-key",
            text: i18n.tc("common.settings-integration")
          },
          {
            to: `/settings#${settingsHashRoutes.github}`,
            icon: "mdi-github",
            text: i18n.tc("common.settings-github")
          },
          {
            to: `/settings#${settingsHashRoutes.generateExtension}`,
            icon: "mdi-archive-arrow-down-outline",
            text: i18n.tc("common.settings-extension"),
            new: true
          }
        ]
      }
    ]
  },
  modules: {
    auth,
    extensions,
    extensionGroups,
    scripts,
    actions,
    documents,
    profile,
    preferences,
    tariffs,
    payments,
    schedule,
    proxy,
    error,
    browserConfigTemplates,
    browserProfiles,
    settings,
    extensionData
  },
  mutations: {
    setLayout(state, payload) {
      state.layout = payload;
    },
    setTableOverflow(state, payload: number) {
      state.tableOverflow = payload;
    }
  },
  actions: {},
  getters: {
    appName: state => state.appName,
    appVersion: state => state.packageVersion,
    layout: state => state.layout,
    tableOverflow: state => state.tableOverflow,
    apiUrl: state => state.apiUrl,
    topNavigationItems: state => state.topNavigationItems(),
    bottomNavigationItems: state => state.bottomNavigationItems()
  }
};

export default new Vuex.Store(store);
