import { Guid } from "guid-typescript";
import { ActionType } from "./ActionType";

export interface Action {
  id: Guid;
  name: string;
  actionType: ActionType;
  inputSettings: any;
  userId: string;
  isCustom?: boolean;
  parentActionId?: Guid;
  lastUpdated: Date;
  version: number;
  savedVersionsCount?: number;
  autoupdate: boolean;
  defaultAccessRights: AccessRights;
  expiration?: Date;
  accessRights?: AccessRights;
}

export interface NestedAction {
  id: Guid;
  userEmail: string;
  parentUpdated: Date;
  version: number;
  expiration?: Date | string;
  accessRights?: AccessRights;
}

export interface ActionUpdate {
  id: number;
  lastUpdated: Date;
  nestedActionId?: Guid;
  parentActionId?: Guid;
  note: string;
  actionVersion?: number;
  type: string;
  actionName?: string;
}

export enum AccessRights {
  Full = 0,
  Edit = 1,
  Read = 2,
  Private = 3
}
