export interface GitHubResponse<TResult> {
  result: TResult;
  isSuccess: boolean;
  status: Status;
  message: string;
}

export enum Status {
  Success = 1,
  Failure = 2,
  Warning = 3
}
