import { Module } from "vuex";
import { RootState } from "../types";
import { SettingsState } from "./types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

export const state: SettingsState = {
  githubClientId: process.env.VUE_APP_GITHUB_CLIENTID ?? "",
  githubRedirectUrl: process.env.VUE_APP_GITHUB_REDIRECT_URL ?? "",
  token: localStorage.getItem("github-token") ?? "",
  githubUsername: localStorage.getItem("github-username") ?? "",
  allowedUploadTotalSize: Number(process.env.VUE_APP_ALLOWED_FILES_TOTAL_SIZE ?? 157286400),
  githubRepo: process.env.VUE_APP_GITHUB_REPO ?? "scenum"
};

const namespaced = false;

export const documents: Module<SettingsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default { state, getters, actions, mutations };
