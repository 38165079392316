import { MutationTree } from "vuex";
import { BrowserConfigTemplatesState } from "./types";
import { BrowserConfigTemplate } from "@/models/BrowserConfigTemplate";
import { Guid } from "guid-typescript";

export const mutations: MutationTree<BrowserConfigTemplatesState> = {
  setBrowserConfigTemplates: (state, templates: BrowserConfigTemplate[]) =>
    (state.templates = templates),
  removeBrowserConfigTemplate: (state, id: Guid) => {
    state.templates = state.templates.filter(template => !(template.id === id));
  },
  setBrowserConfigTemplate: (state, template: BrowserConfigTemplate) => {
    state.templates.push(template);
  },
  updateBrowserConfigTemplate: (
    state,
    updatedTemplate: BrowserConfigTemplate
  ) => {
    const updatedTemplateIndex = state.templates.findIndex(
      template => template.id === updatedTemplate.id
    );
    state.templates[updatedTemplateIndex] = updatedTemplate;
    state.templates = [...state.templates];
  }
};
