import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import Axios from "axios";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "auth-layout"
    }
  },
  {
    path: "/extensions",
    name: "Extensions",
    component: () => import("@/views/Extensions.vue")
  },
  {
    path: "/scripts",
    name: "Scripts",
    component: () => import("@/views/Scripts.vue")
  },
  {
    path: "/actions",
    name: "Actions",
    component: () => import("@/views/Actions.vue")
  },
  {
    path: "/documents",
    name: "Documents",
    component: () => import("@/views/Documents.vue")
  },
  {
    path: "/proxy",
    name: "Proxy",
    component: () => import("@/views/Proxy.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings.vue")
  },
  // redirects
  {
    path: "/payments/success",
    redirect: "/extensions"
  },
  {
    path: "/payments/failure",
    redirect: "/extensions"
  },
  {
    path: "/",
    redirect: "/extensions"
  },
  // github return url
  {
    path: "/oauth/github",
    name: "githubReturnUrl",
    beforeEnter: (to: any, from: any, next: any) => {
      const githubCode = to.query["code"];
      if (githubCode) {
        localStorage.setItem("github-code", githubCode);
        localStorage.setItem("github-login-result", "true");
      } else {
        localStorage.setItem("github-login-result", "false");
      }

      next({ name: "Settings" });
    }
  }
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to: any, from: any, next: any) => {
  const isAuthenticated = store.getters.isAuthenticated;

  const impersonateToken = to.query["impersonateToken"];

  if (impersonateToken) {
    Axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${impersonateToken}`;
    localStorage.setItem("access-token", impersonateToken);
    store.commit("authSuccess", impersonateToken);
    next({ path: "/" });
    return;
  }

  if (to.path !== "/login" && !isAuthenticated) next({ path: "/login" });
  else if (to.path === "/login" && isAuthenticated) next({ path: "/" });
  else next();
});

export default router;
