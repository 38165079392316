import { Proxy } from "@/models/Proxy";
import { MutationTree } from "vuex";
import { ProxyState } from "./types";

export const mutations: MutationTree<ProxyState> = {
    replaceProxies: (state: ProxyState, proxies: Proxy[]) => {
        state.proxy = proxies
    },

    addProxies: (state: ProxyState, proxies: Proxy[]) => {
        state.proxy = [...state.proxy, ...proxies]
    },

    editProxy: (state: ProxyState, updatedProxy: Proxy) => {
        const proxy = state.proxy.find(proxy => proxy.id === updatedProxy.id)
        if(proxy){
            proxy.id = updatedProxy.id
            proxy.host = updatedProxy.host
            proxy.ipChangeLink = updatedProxy.ipChangeLink
            proxy.login = updatedProxy.login
            proxy.password = updatedProxy.password
            proxy.port = updatedProxy.port
            proxy.proxyStatus = updatedProxy.proxyStatus
            proxy.threadsCount = updatedProxy.threadsCount
            proxy.type = updatedProxy.type
        }
    },

    removeProxies: (state: ProxyState, proxies: Proxy[]) => {
        proxies.forEach( deletedProxy => {
            const index = state.proxy.findIndex( proxy => proxy.id == deletedProxy.id)
            if (index > -1) 
                state.proxy.splice(index,1)
        })
    },
};