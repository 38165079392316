import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { ExtensionsState } from "./types";
import { RootState } from "../types";
import { EditMode } from "@/models/enums";

export const state: ExtensionsState = {
  extensions: [],
  editMode: EditMode.Extension,
  selectedExtensions: [],
  totalCount: 0,
  filters: {}
};

const namespaced = false;

export const documents: Module<ExtensionsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default { state, getters, actions, mutations };
