import { Proxy } from "@/models/Proxy";
import { GetterTree } from "vuex";
import { RootState } from "../types";
import { ProxyState } from "./types";
import { ProxyNameListItem } from "@/models/ProxyTypes";

export const getters: GetterTree<ProxyState, RootState> = {
  allProxies: state => state.proxy,
  proxyNameList: state =>
    state.proxy.map(
      (proxy: Proxy): ProxyNameListItem => {
        return {
          text: proxy.name,
          value: proxy.id
        };
      }
    ),
  getProxyById: state => (id: Proxy["id"]) =>
    state.proxy.find(proxy => proxy.id === id)
};
