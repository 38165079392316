<template>
  <v-btn
    v-if="count > 0"
    v-on:click="setShowModal(true)"
    :loading="loading"
    class="scripts_btn"
    color="red"
    rounded
  >
    {{ count }}
    <modal :show="showModal" @setShowModal="setShowModal" />
  </v-btn>
</template>

<style scoped>
.scripts_btn {
  min-width: 36px !important;
  padding: 0 !important;
  margin-right: 10px;
}
</style>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import GetScriptsModal from "@/components/scripts/GetScriptsModal.vue";
import { Action } from "vuex-class";

@Component({
  components: {
    modal: GetScriptsModal
  }
})
export default class GetScripts extends Vue {
  private count = 0;
  private loading = false;

  private showModal = false;
  private setShowModal(value: boolean) {
    this.showModal = value;
  }

  @Action("getScriptsCount")
  private getScriptsCount!: () => Promise<number>;

  private async _getScriptsCount() {
    this.loading = true;
    this.count = await this.getScriptsCount();
    this.loading = false;
  }

  @Watch("showModal", { immediate: false })
  private async onShowModalChange(value: boolean) {
    if (!value) {
      await this._getScriptsCount();
    }
  }

  private async mounted() {
    await this._getScriptsCount();
  }
}
</script>
