import { MutationTree } from "vuex";
import { ExtensionsState } from "./types";
import { Extension } from "@/models/Extension";
import { EditMode, ScriptStatus } from "@/models/enums";
import { Guid } from "guid-typescript";
import { ExtensionScript } from "@/models/ExtensionScript";
import { Account } from "@/models/Account";
import { BrowserConfig } from "@/models/BrowserConfig";
import { ColumnSettingValue } from "@/models/ColumnSettingValue";
import { ExtensionProxiesModel } from "@/models/ExtensionProxiesModel";
import { ExtensionsProxiesModel } from "@/models/ExtensionsProxiesModel";

export const mutations: MutationTree<ExtensionsState> = {
  setExtensions: (state: ExtensionsState, extensions: Extension[]) => (state.extensions = extensions),
  addExtension: (state: ExtensionsState, extension: Extension) =>
    state.extensions.push(extension),
  removeExtension: (state: ExtensionsState, id: Guid) => {
    const index: number = state.extensions.findIndex(e => e.id === id);
    if (index !== -1) {
      state.extensions.splice(index, 1);
    }
  },
  updateExtension: (state: ExtensionsState, extension: Extension) => {
    const index: number = state.extensions.findIndex(
      e => e.id === extension.id
    );
    if (index !== -1) {
      const extensionData = state.extensions[index];

      const newData = Object.assign(extensionData, extension);
      state.extensions.splice(index, 1, newData);
    }
  },
  updateExtensionScript: (
    state: ExtensionsState,
    { id, script }: { id: Guid; script: ExtensionScript }
  ) => {
    const index: number = state.extensions.findIndex(e => e.id === id);
    if (index !== -1) {
      state.extensions[index].script = script;
    }
  },
  updateExtensionAccount: (
    state: ExtensionsState,
    { id, account }: { id: Guid; account: Account }
  ) => {
    const index: number = state.extensions.findIndex(e => e.id === id);
    if (index !== -1) {
      state.extensions[index].account = account;
    }
  },
  removeExtensionAccount: (state: ExtensionsState, id: Guid) => {
    const index: number = state.extensions.findIndex(e => e.id === id);
    if (index !== -1) {
      state.extensions[index].account = undefined;
    }
  },
  setEditMode: (state: ExtensionsState, editMode: EditMode) =>
    (state.editMode = editMode),
  setSelectedExtensions: (state: ExtensionsState, extensions: Extension[]) =>
    (state.selectedExtensions = extensions),
  removeSelectedExtension: (state: ExtensionsState, extension: Extension) => {
    const index: number = state.selectedExtensions.findIndex(
      e => e.id === extension.id
    );
    if (index !== -1) {
      state.selectedExtensions.splice(index, 1);
    }
  },
  removeSelectedExtensions: (state: ExtensionsState, ids: Guid[]) => {
    state.selectedExtensions = state.selectedExtensions.filter(
      ext => !ids.find(e => e === ext.id)
    );
  },
  setTotalCount: (state: ExtensionsState, totalCount: number) =>
    (state.totalCount = totalCount),
  setFilters: (state: ExtensionsState, filters: []) =>
    (state.filters = filters),
  changeScriptStatus: (
    state: ExtensionsState,
    { id, status }: { id: Guid; status: ScriptStatus }
  ) => {
    const index: number = state.extensions.findIndex(e => e.id === id);
    if (index !== -1) {
      const script = state.extensions[index].script;
      if (script) {
        script.status = status;
      }
    }
  },
  updateBrowserConfig: (
    state: ExtensionsState,
    browserConfig: BrowserConfig
  ) => {
    const index: number = state.extensions.findIndex(e => e.id === browserConfig.extensionId);
    if (index !== -1) {
      state.extensions[index].browserConfig = browserConfig;
    }
  },
  removeBrowserConfig: (state: ExtensionsState, id: Guid) => {
    const index: number = state.extensions.findIndex(e => e.id === id);
    if (index !== -1) {
      state.extensions[index].browserConfig = undefined;
    }
  },
  renameExtensionGroup: (
    state: ExtensionsState,
    { id, name }: { id: Guid; name: string }
  ) => {
    const index: number = state.extensions.findIndex(e => e.id === id);
    if (index !== -1) {
      state.extensions[index].name = name;
    }
  },
  updateExtensionGroupThreadCount: (
    state: ExtensionsState,
    { id, threadCount }: { id: Guid; threadCount: number }
  ) => {
    const index: number = state.extensions.findIndex(e => e.id === id);
    if (index !== -1) {
      state.extensions[index].threadCount = threadCount;
    }
  },
  updateExtensionGroupColumnSettings: (
    state: ExtensionsState,
    { id, columnSettings }: { id: Guid; columnSettings: ColumnSettingValue[] }
  ) => {
    const index: number = state.extensions.findIndex(e => e.id === id);
    if (index !== -1) {
      state.extensions[index].columnSettings = columnSettings;
    }
  },
  removeAllProxyExtension: (state: ExtensionsState, extensionID: Guid) => {
    const extension = state.extensions.find(extension => extension.id == extensionID)
    if(extension)
      extension.proxies = [] 
  },

  addProxiesToExtension: (state: ExtensionsState, model: ExtensionProxiesModel) => {
    const extension = state.extensions.find(extension => extension.id == model.extensionId)
    if(extension)
      extension.proxies = [...extension.proxies, ...model.proxies]
  }
};
