import { MutationTree } from "vuex";
import { SettingsState } from "./types";
import { GithubTokenResponse } from "@/models/Settings/Github/GithubTokenResponse";

export const mutations: MutationTree<SettingsState> = {
  connectGithubSuccess: (state: SettingsState, data: GithubTokenResponse) => {
    state.token = data.token;
    state.githubUsername = data.username;
  },
  removeToken: (state: SettingsState) => {
    state.token = "";
    state.githubUsername = "";
  }
};
