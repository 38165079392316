import { MutationTree } from "vuex";
import { TariffsState } from "./types";
import { Tariff } from "@/models/Tariff";

export const mutations: MutationTree<TariffsState> = {
  setAllTariffs: (state: TariffsState, tariffs: Tariff[]) =>
    (state.tariffs = tariffs),
  setSelectedTariff: (state: TariffsState, tariff: Tariff) =>
    (state.selectedTariff = tariff),
  setCurrentTariff: (state: TariffsState, tariff: Tariff) =>
    (state.currentTariff = tariff)
};
