import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { ScriptsState } from "./types";
import { RootState } from "../types";

export const state: ScriptsState = {
  scripts: [],
  scriptsBySocialNetwork: [],
  actionRows: [],
  selectedScript: null,
  selectedScriptSocialNetwork: null
};

const namespaced = false;

export const documents: Module<ScriptsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default { state, getters, actions, mutations };
