import { ActionTree } from "vuex";
import { RootState } from "../types";
import Axios from "axios";
import { TariffsState } from "./types";

export const actions: ActionTree<TariffsState, RootState> = {
  fetchTariffs({ commit }: any) {
    return new Promise((resolve, reject) => {
      Axios.get("/api/tariffs")
        .then(response => {
          commit("setAllTariffs", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  }
};
