import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { AuthState, AuthStatus } from "./types";
import { RootState } from "../types";

export const state: AuthState = {
  accessToken: localStorage.getItem("access-token") || "",
  status: AuthStatus.None
};

const namespaced = false;

export const documents: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default { state, getters, actions, mutations };
