import { MutationTree } from "vuex";
import { PreferencesState } from "./types";
import { Preference } from "@/models/Preference";

export const mutations: MutationTree<PreferencesState> = {
  setPreferences: (state: PreferencesState, preferences: Preference[]) =>
    (state.preferences = preferences),
  updatePreference: (state: PreferencesState, preference: Preference) => {
    const index: number = state.preferences.findIndex(
      p => p.preference.name === preference.preference.name
    );
    if (index !== -1) {
      state.preferences[index].value = preference.value;
    }
  }
};
