import Vue from "vue";
import Vuetify from "vuetify/lib";
import en from "vuetify/src/locale/en";
import ru from "vuetify/src/locale/ru";
import pt from "vuetify/src/locale/pt";
import { default as i18n } from "@/i18n";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, ru, pt },
    current: i18n.locale
  }
});
