import { ActionTree } from "vuex";
import { PreferencesState } from "./types";
import { RootState } from "../types";
import Axios from "axios";
import { GetPreferencesModel } from "@/models/GetPreferencesModel";
import { Preference } from "@/models/Preference";

export const actions: ActionTree<PreferencesState, RootState> = {
  fetchPreferences(
    { commit }: any,
    model: GetPreferencesModel
  ): Promise<Preference[]> {
    return new Promise((resolve, reject) => {
      Axios.get("/api/user-preferences", { params: model })
        .then(response => {
          const preferences: Preference[] = response.data.data;
          commit("setPreferences", preferences);
          resolve(preferences);
        })
        .catch(error => reject(error.response.data));
    });
  },
  updatePreference(
    { commit }: any,
    { name, value }: { name: string; value: any }
  ): Promise<Preference> {
    return new Promise((resolve, reject) => {
      Axios.patch(`/api/user-preferences`, {
        name: name,
        value: value.toString()
      })
        .then(response => {
          commit("updatePreference", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  }
};
