export enum ErrorCode {
  // More error codes can be found on the server side.
  // Look up in file: Exceptions/ErrorCodes.cs
  // General
  UnknownError = 1,
  // Account
  ProxyNotSelected = 105,
  ProfileNotSelected = 106,
  // BrowserApi
  LoginFail = 10002,
  UnableToCreateProfile = 10003
}
