<template>
  <v-snackbar v-model="showError" :timeout="10000" color="red darken-3">
    {{ errorMessage }}
  </v-snackbar>
</template>

<script lang="ts">
import { ErrorState } from "@/store/error/types";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component
export default class ErrorSnackbar extends Vue {
  @Getter("isErrorOccured")
  private isErrorOccured!: ErrorState["isErrorOccured"];

  @Getter("errorMessageObject")
  public errorMessageObject!: Pick<ErrorState, "errorCode" | "errorType">;

  @Getter("fallbackErrorMessage")
  public fallbackErrorMessage!: string;

  @Action("resetError")
  private resetError!: () => void;

  public get showError() {
    return this.isErrorOccured;
  }

  public set showError(value: boolean) {
    if (!value) {
      this.resetError();
    }
  }

  public get errorMessage() {
    const fallbackMessage = this.fallbackErrorMessage || this.$t("errors.1");
    if (this.errorMessageObject.errorType === "http-errors") {
      return `${this.$t("http-errors.status-code")}: ${
        this.errorMessageObject.errorCode
      }. ${fallbackMessage}`;
    }
    const errorMessagePath = `${this.errorMessageObject.errorType}.${this.errorMessageObject.errorCode}`;
    const isMessageExists = this.$te(errorMessagePath);
    return isMessageExists ? this.$t(errorMessagePath) : fallbackMessage;
  }
}
</script>
