import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "../types";
import { ProxyState } from "./types"

export const state: ProxyState = {
    proxy: []
};

const namespaced = false;

export const documents: Module<ProxyState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};

export default { state, getters, actions, mutations };