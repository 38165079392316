export const repeatTypeLimit = 60;

export const repeatTime = Object.freeze({
  1: "everyDay",
  2: "everyTwoDays",
  7: "everyWeek",
  10: "everyTenDays",
  15: "everyFifteenDays",
  30: "everyMonth"
});

export const endTypeLimit = 10;

export const countLimit = 10;

export const paginationLimit = Number(
  process.env.VUE_APP_EXTENSIONS_TABLE_PAGINATION || 10
);
