import { MutationTree } from "vuex";
import { AuthState, AuthStatus } from "./types";

export const mutations: MutationTree<AuthState> = {
  authRequest: (state: AuthState) => (state.status = AuthStatus.Loading),
  authSuccess: (state: AuthState, token: string) => {
    state.status = AuthStatus.Success;
    state.accessToken = token;
  },
  authError: (state: AuthState) => (state.status = AuthStatus.Error),
  authLogout: (state: AuthState) => {
    state.status = AuthStatus.None;
    state.accessToken = "";
  }
};
