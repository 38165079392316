import { MutationTree } from "vuex";
import { ErrorState } from "./types";

export const mutations: MutationTree<ErrorState> = {
  setIsErrorOccured: (state, isShowError: boolean) => {
    state.isErrorOccured = isShowError;
  },
  setErrorMessage: (
    state,
    error: Pick<ErrorState, "errorType" | "errorCode">
  ) => {
    state.errorCode = error.errorCode;
    state.errorType = error.errorType;
  },
  setFallbackErrorMessage: (state, message: string) => {
    state.fallbackMessage = message;
  }
};
