import { GetterTree } from "vuex";
import { ExtensionsState } from "./types";
import { RootState } from "../types";
import { ScriptStatus } from "@/models/enums";
import { Guid } from "guid-typescript";
import { Extensions } from "@/views";

export const getters: GetterTree<ExtensionsState, RootState> = {
  allExtensions: (state: ExtensionsState) => state.extensions,
  activeScriptsCount: (state: ExtensionsState): number => {
    return state.extensions.filter(
      e =>
        e.script?.status === ScriptStatus.InProgress ||
        e.script?.status === ScriptStatus.Pause
    ).length;
  },
  editMode: (state: ExtensionsState) => state.editMode,
  selectedExtensions: (state: ExtensionsState) => state.selectedExtensions,
  totalCount: (state: ExtensionsState) => state.totalCount,
  filters: (state: ExtensionsState) => state.filters,
};