import { ActionTree } from "vuex";
import { RootState } from "../types";
import Axios from "axios";
import { ProfileState } from "./types";

export const actions: ActionTree<ProfileState, RootState> = {
  fetchProfileInfo({ commit }) {
    return new Promise((resolve, reject) => {
      Axios.get("/api/profile")
        .then(response => {
          commit("setProfileInfo", response.data);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  acceptTermsAndConditions({ commit }) {
    return new Promise((resolve, reject) => {
      Axios.post("/api/profile/accept-terms-and-conditions")
        .then(() => {
          commit("setTermsAndConditions", true);
          resolve(true);
        })
        .catch(error => reject(error.response.data));
    });
  }
};
