import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { DocumentsState } from "./types";
import { RootState } from "../types";

export const state: DocumentsState = {
  documents: [],
  totalCount: 0
};

const namespaced = false;

export const documents: Module<DocumentsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default { state, getters, actions, mutations };
