import { MutationTree } from "vuex";
import { ActionsState } from "./types";
import { Action } from "@/models/Action";
import { ActionType } from "@/models/ActionType";
import { ScriptActionRow } from "@/models/ScriptActionRow";
import { Guid } from "guid-typescript";
import { SocialNetwork } from "@/models/enums/SocialNetwork";

export const mutations: MutationTree<ActionsState> = {
  setActions: (state: ActionsState, actions: Action[]) =>
    (state.actions = actions),
  addAction: (state: ActionsState, action: Action) =>
    state.actions.push(action),
  updateAction: (state: ActionsState, action: Action) => {
    let index: number = state.actions.findIndex(a => a.id === action.id);
    state.actions[index] = action;

    index = state.actionsByType.findIndex(a => a.id === action.id);
    state.actionsByType[index] = action;
  },
  removeAction: (state: ActionsState, id: Guid) => {
    const index: number = state.actions.findIndex(a => a.id === id);
    if (index !== -1) {
      state.actions.splice(index, 1);
    }
  },
  setSelectedSocialNetwork: (
    state: ActionsState,
    socialNetwork: SocialNetwork | null
  ) => (state.selectedSocialNetwork = socialNetwork),
  setSelectedActionType: (
    state: ActionsState,
    actionType: ActionType | null
  ) => {
    state.selectedActionType = actionType;
    state.selectedAction = null;
  },
  setActionTypes: (state: ActionsState, actionTypes: ActionType[]) =>
    (state.actionTypes = actionTypes),
  setSelectedAction: (state: ActionsState, action: Action | string | null) =>
    (state.selectedAction = action),

  setRandomActionRows: (state: ActionsState, actionRows: ScriptActionRow[]) =>
    (state.actionRows = actionRows),
  removeRandomActionRow: (state: ActionsState, actionRow: ScriptActionRow) => {
    const index: number = state.actionRows.indexOf(actionRow);
    state.actionRows.splice(index, 1);
  },
  addRandomActionRow: (state: ActionsState) =>
    state.actionRows.push({
      selectedActionId: null,
      selectedActionType: null,
      socialNetwork: null,
      actions: [],
      hasFixedOrder: false,
      timeLimit: 0
    }),
  setActionsByType: (state: ActionsState, actions: Action[]) =>
    (state.actionsByType = actions),
  removeActionByType: (state: ActionsState, id: Guid) => {
    const index: number = state.actionsByType.findIndex(a => a.id === id);
    if (index !== -1) {
      state.actionsByType.splice(index, 1);
    }
  },
  addActionByType: (state: ActionsState, action: Action) =>
    state.actionsByType.push(action),
  setActionOptions: (state: ActionsState, options: any[]) =>
    (state.actionOptions = options),
  addActionOption: (state: ActionsState, option: any) =>
    state.actionOptions.push(option),
  setActionOptionValues: (state: ActionsState, optionValues: any) =>
    (state.actionOptionValues = optionValues),
  setActionOptionValue: (state: ActionsState, { index, optionValue }) => {
    state.actionOptionValues = Object.assign({}, state.actionOptionValues, {
      [index]: optionValue
    });
  }
};
