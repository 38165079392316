import Axios from "axios";
import { ActionTree } from "vuex";
import { ExtensionDataState } from "./types";
import { RootState } from "../types";
import {
  CreateExtensionDataTemplateModel,
  UpdateExtensionDataTemplateModel,
  ExtensionDataTemplate,
  DownloadDataModel,
  AccountDataResultModel
} from "@/models/ExtensionData";

export const actions: ActionTree<ExtensionDataState, RootState> = {
  fetchExtensionDataTemplates({ commit }) {
    return new Promise<void>((resolve, reject) => {
      Axios.get("/api/extension-data/templates")
        .then(response => {
          commit("setExtensionDataTemplates", response.data.data);
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  createExtensionDataTemplate(
    { commit },
    model: CreateExtensionDataTemplateModel
  ) {
    return new Promise<ExtensionDataTemplate>((resolve, reject) => {
      Axios.post("/api/extension-data/templates", model)
        .then(response => {
          commit("setExtensionDataTemplate", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => reject(error));
    });
  },

  deleteExtensionDataTemplates({ commit }, ids: ExtensionDataTemplate["id"][]) {
    return new Promise<void>((resolve, reject) => {
      Axios.delete("/api/extension-data/templates", { data: { ids } })
        .then(() => {
          ids.forEach(id => commit("removeExtensionDataTemplate", id));
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  updateExtensionDataTemplate(
    { commit },
    model: UpdateExtensionDataTemplateModel
  ) {
    return new Promise<ExtensionDataTemplate>((resolve, reject) => {
      const { id, ...restModel } = model;

      Axios.patch(`/api/extension-data/templates/${id}`, restModel)
        .then(response => {
          commit("updateExtensionDataTemplate", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => reject(error));
    });
  },

  downloadExtensionData({ getters }, model: DownloadDataModel) {
    return new Promise<AccountDataResultModel[]>((resolve, reject) => {
      const body = {
        token: getters?.githubToken,
        username: getters?.githubUsername,
        ...model
      };

      Axios.post(`/api/extension-data`, body)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => reject(error));
    });
  }
};
