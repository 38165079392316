import { ActionTree } from "vuex";
import { ActionsState } from "./types";
import { RootState } from "../types";
import { AccessRights, Action, NestedAction } from "@/models/Action";
import Axios from "axios";
import { ActionType } from "@/models/ActionType";
import { CreateActionModel } from "@/models/CreateActionModel";
import { UpdateActionModel } from "@/models/UpdateActionModel";
import { Guid } from "guid-typescript";

const mapAction = (data: any) => {
  const actionType = {
    id: data.actionType.id,
    name: data.actionType.name,
    inputSettingDefinitions:
      data.actionType.inputSettingDefinitions?.rootElement ?? data.actionType.inputSettingDefinitions,
    isRandom: data.actionType.isRandom,
    isAvailable: data.actionType.isAvailable,
    socialNetwork: data.actionType.socialNetwork
  };
  return {
    id: data.id,
    name: data.name,
    actionType: actionType,
    inputSettings: data.inputSettings?.rootElement ?? data.inputSettings,
    userId: data.userId,
    isCustom: actionType.name.toLowerCase() == "custom",
    parentActionId: data.parentActionId,
    lastUpdated: data.lastUpdated,
    version: data.version,
    autoupdate: data.autoUpdate,
    savedVersionsCount: data.savedVersionsCount,
    defaultAccessRights: data.defaultAccessRights,
    expiration: data.expiration,
    accessRights: data.accessRights ?? AccessRights.Full
  };
};

export const actions: ActionTree<ActionsState, RootState> = {
  fetchActions({ commit }: any) {
    return new Promise((resolve, reject) => {
      Axios.get("/api/actions")
        .then(response => {
          const actions: Action[] = [];
          for (const action of response.data.data) {
            const mappedAction = mapAction(action);
            actions.push(mappedAction);
          }
          commit("setActions", actions);
          resolve(actions);
        })
        .catch(error => reject(error.response.data));
    });
  },
  getRandomActions({ commit }: any) {
    return new Promise((resolve, reject) => {
      Axios.get("/api/actions", { params: { random: true } })
        .then(response => {
          const actions: Action[] = [];
          for (const action of response.data.data) {
            const mappedAction = mapAction(action);
            actions.push(mappedAction);
          }

          resolve(actions);
        })
        .catch(error => reject(error.response.data));
    });
  },
  getActionsByType({ commit }: any, actionTypeId: number): Promise<Action[]> {
    return new Promise((resolve, reject) => {
      Axios.get("/api/actions", { params: { actionTypeId: actionTypeId } })
        .then(response => {
          const actions: Action[] = [];
          for (const action of response.data.data) {
            const mappedAction = mapAction(action);
            actions.push(mappedAction);
          }
          resolve(actions);
        })
        .catch(error => reject(error.response.data));
    });
  },
  fetchActionTypes({ commit }: any): Promise<ActionType[]> {
    return new Promise((resolve, reject) => {
      Axios.get("/api/action-types")
        .then(response => {
          const actionTypes: ActionType[] = [];
          for (const actionType of response.data.data) {
            actionTypes.push({
              id: actionType.id,
              name: actionType.name,
              inputSettingDefinitions:
                actionType.inputSettingDefinitions?.rootElement ?? actionType.inputSettingDefinitions,
              isRandom: actionType.isRandom,
              helpLinks: actionType.helpLinks,
              isAvailable: actionType.isAvailable,
              socialNetwork: actionType.socialNetwork
            });
          }
          commit("setActionTypes", actionTypes);
          resolve(actionTypes);
        })
        .catch(error => reject(error.response.data));
    });
  },
  createAction({ commit }: any, model: CreateActionModel) {
    return new Promise((resolve, reject) => {
      Axios.post("/api/actions", model)
        .then(response => {
          const mappedAction = mapAction(response.data.data);
          commit("addAction", mappedAction);
          resolve(mappedAction);
        })
        .catch(error => reject(error.response.data));
    });
  },
  updateAction({ commit }: any, model: UpdateActionModel) {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/actions/${model.id}`, model.data)
        .then(response => {
          const mappedAction = mapAction(response.data.data);
          commit("updateAction", mappedAction);
          resolve(mappedAction);
        })
        .catch(error => reject(error.response.data));
    });
  },
  deleteAction({ commit }: any, id: Guid) {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/actions/${id}`)
        .then(response => {
          commit("removeAction", id);
          commit("removeActionByType", id);
          commit("setSelectedAction", null);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  getNestedActions({ commit }: any, id: Guid) {
    return new Promise(resolve => {
      Axios.get(`/api/actions/nested/${id}`)
        .then(response => resolve(response.data.data))
        .catch(() => resolve(null));
    });
  },
  deleteNestedActions({ commit }: any, ids: Guid[]) {
    const data = {
      ids: ids
    };
    return new Promise(resolve => {
      Axios.post("/api/actions/nested/delete", data)
        .then(response => resolve(response.data.data))
        .catch(() => resolve([]));
    });
  },
  updateNestedActions({ commit }: any, data: any) {
    return new Promise(resolve => {
      Axios.post("/api/actions/nested/update", data)
        .then(response => resolve(response.data.data))
        .catch(() => resolve([]));
    });
  },
  getNestedActionUpdate({ commit }: any, id: Guid) {
    return new Promise(resolve => {
      Axios.get(`/api/actions/nested/update/${id}`)
        .then(response => resolve(response.data.data))
        .catch(() => resolve(null));
    });
  },
  getNestedActionUpdates({ commit }: any) {
    return new Promise(resolve => {
      Axios.get("/api/actions/nested/updates")
        .then(response => resolve(response.data.data))
        .catch(() => resolve(null));
    });
  },
  markNestedActionUpdatesNotified({ commit }: any, ids: number[]) {
    const data = {
      ids: ids
    };
    return new Promise<void>(resolve => {
      Axios.post("/api/actions/nested/updates", data)
        .then(() => resolve())
        .catch(err => {
          console.error(err);
          resolve();
        });
    });
  },
  updateNestedAction({ commit }: any, id: Guid) {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/actions/nested/update/${id}`)
        .then(response => {
          const mappedAction = mapAction(response.data.data);
          commit("updateAction", mappedAction);
          commit("setSelectedAction", mappedAction);
          resolve(mappedAction);
        })
        .catch(error => reject(error.response.data));
    });
  },
  setAutoupdateState({ commit }: any, data: any) {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/actions/nested/${data.id}/autoupdate?enabled=${data.enabled}`)
        .then(response => resolve(response.data.data))
        .catch(error => reject(error.response.data));
    });
  },
  setDefaultAccessRights({ commit }: any, data: any) {
    return new Promise<void>((resolve, reject) => {
      Axios.post(`/api/actions/nested/${data.id}/access?rights=${data.rights}`)
        .then(() => resolve())
        .catch(error => reject(error.response.data));
    });
  },
  manageNestedActions({ commit }: any, nestedActions: NestedAction[]) {
    const data = {
      nestedActions: nestedActions
    };
    return new Promise(resolve => {
      Axios.post("/api/actions/nested/manage", data)
        .then(response => resolve(response.data.data))
        .catch(() => resolve([]));
    });
  }
};
