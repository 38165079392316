import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import titleMixin from "./mixins/TitleMixin";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import AuthLayout from "./layouts/AuthLayout.vue";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueFilterDateParse from "@vuejs-community/vue-filter-date-parse";
import axios from "axios";
import i18n from "./i18n";
import frag from "vue-frag";

Vue.component("default-layout", DefaultLayout);
Vue.component("auth-layout", AuthLayout);

Vue.use(VueFilterDateFormat);
Vue.use(VueFilterDateParse);

Vue.config.productionTip = false;

Vue.mixin(titleMixin);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const token = localStorage.getItem("access-token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

Vue.directive("frag", frag);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
