import Axios from "axios";
import { Proxy } from "@/models/Proxy";
import { ProxyState } from "./types";
import { RootState } from "../types";
import { ActionTree } from "vuex";
import { CreateProxyModel } from "@/models/ProxyTypes";

export const actions: ActionTree<ProxyState, RootState> = {
  createProxy({ commit, dispatch }, proxies: CreateProxyModel[]) {
    return new Promise<void>(resolve => {
      Axios.post("/api/proxy", {
        proxies: proxies
      })
        .then(response => {
          if (response.data.succeeded) {
            commit("addProxies", response.data.data.proxies);
            resolve();
          } else {
            return Promise.reject(response);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  // TODO: refactor actions below to use correct types
  createProxies({ commit }: any, proxies: Proxy[]): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post("/api/proxy", {
        proxies: proxies
      })
        .then(response => {
          if (response.data.succeeded) {
            commit("addProxies", response.data.data.proxies);
            resolve(response.data.data);
          } else reject(response);
        })
        .catch(error => reject(error));
    });
  },

  fetchProxies({ commit }: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get("/api/proxy")
        .then(response => {
          if (response.data.succeeded) {
            resolve(response.data.data);
            commit("replaceProxies", response.data.data);
          } else reject(response);
        })
        .catch(error => reject(error));
    });
  },

  updateProxy({ commit }: any, proxy: Proxy): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.put("/api/proxy", proxy)
        .then(response => {
          if (response.data.succeeded) {
            resolve(response.data.data);
            commit("editProxy", response.data.data);
          } else {
            reject(response.data.data);
          }
        })
        .catch(error => reject(error));
    });
  },

  deleteProxies({ commit }: any, proxies: Proxy[]): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/proxy`, {
        data: {
          ids: proxies.map(proxy => proxy.id)
        }
      })
        .then(response => {
          if (response.data.succeeded) {
            resolve(response.data.data);
            commit("removeProxies", proxies);
          } else reject(response.data.data);
        })
        .catch(error => reject(error));
    });
  },

  searchProxies({ commit }: any, searchString: string): Promise<any> {
    return Axios.get(`/api/proxy?searchString=${searchString}`);
  },

  checkProxyStatus({ commit }: any, proxy: Proxy): Promise<any> {
    return new Promise((resolve, reject) => {
      proxy.proxyStatus = -1;
      Axios.get(`/api/proxy/${proxy.id}/checkproxy`)
        .then(response => {
          proxy.proxyStatus = response.data.data;
          resolve(response.data);
        })
        .catch(e => {
          proxy.proxyStatus = 0;
          console.error(e);
          reject(e);
        });
    });
  },

  rebootProxy({ commit }: any, proxy: Proxy): Promise<any> {
    return new Promise((resolve, reject) => {
      proxy.proxyStatus = -1;
      Axios.get(`/api/proxy/${proxy.id}/rebootproxy`)
        .then(response => {
          proxy.proxyStatus = response.data.data;
          resolve(response.data);
        })
        .catch(e => {
          proxy.proxyStatus = 0;
          console.error(e);
          reject(e);
        });
    });
  }
};
