export enum Locales {
  EN = "en",
  RU = "ru",
  PT = "pt",
}

export const LOCALES = [
  { value: Locales.EN, caption: "English" },
  { value: Locales.RU, caption: "Русский" },
  { value: Locales.PT, caption: "Português" }
];
