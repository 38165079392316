import { MutationTree } from "vuex";
import { ExtensionDataState } from "./types";
import { ExtensionDataTemplate } from "@/models/ExtensionData";

export const mutations: MutationTree<ExtensionDataState> = {
  setExtensionDataTemplates: (state, templates: ExtensionDataTemplate[]) =>
    (state.templates = templates),
  setExtensionDataTemplate: (state, template: ExtensionDataTemplate) => {
    state.templates.push(template);
  },
  removeExtensionDataTemplate: (state, id: ExtensionDataTemplate["id"]) => {
    state.templates = state.templates.filter(template => !(template.id === id));
  },
  updateExtensionDataTemplate: (
    state,
    updatedTemplate: ExtensionDataTemplate
  ) => {
    const updatedTemplateIndex = state.templates.findIndex(
      template => template.id === updatedTemplate.id
    );
    state.templates[updatedTemplateIndex] = updatedTemplate;
    state.templates = [...state.templates];
  }
};
