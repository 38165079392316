import { GetterTree } from "vuex";
import { ErrorState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<ErrorState, RootState> = {
  isErrorOccured: state => state.isErrorOccured,
  errorMessageObject: ({ errorCode, errorType }) => {
    return { errorCode, errorType };
  },
  fallbackErrorMessage: state => state.fallbackMessage
};
