import { MutationTree } from "vuex";
import { ProfileState } from "./types";
import { ProfileInfo } from "@/models/ProfileInfo";

export const mutations: MutationTree<ProfileState> = {
  setProfileInfo: (state: ProfileState, profileInfo: ProfileInfo) =>
    (state.profileInfo = profileInfo),
  setTermsAndConditions: (state: ProfileState) => {
    if (state.profileInfo != null) {
      state.profileInfo.isTermsAndConditionsAccepted = true;
    }
  }
};
