<template>
  <v-navigation-drawer
    app
    clipped
    color="grey darken-3"
    dark
    :value="display"
    @input="onDisplayChanged($event)"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
  >
    <v-list dense nav>
      <v-list-item
        v-for="item in topItems"
        :key="item.route"
        link
        :to="item.route"
        :disabled="item.disabled"
      >
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
        <v-chip v-if="item.new" x-small class="ma-2" color="red">New</v-chip>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-divider></v-divider>
      <v-list dense nav v-if="bottomItems?.length || helpLinks?.length">
        <v-list-group
          v-for="item in bottomItems"
          :value="areSettingsShown"
          :key="item.route"
          :to="item.route"
          :disabled="item.disabled"
          color="white"
        >
          <template v-slot:activator>
            <router-link
              :to="item.route"
              class="v-list-item pl-0"
              style="color:whitesmoke"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-chip v-if="item.new" x-small class="ma-2" color="red">
                New
              </v-chip>

              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </router-link>
          </template>

          <v-list-item
            v-for="sublink in item.subLinks"
            :to="sublink.to"
            :key="sublink.text"
            class="ml-2"
          >
            <v-list-item-icon>
              <v-icon>{{ sublink.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-chip v-if="sublink.new" x-small class="ma-2 ml-0" color="red">
                New
              </v-chip>
              {{ sublink.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-divider v-if="bottomItems?.length && helpLinks?.length"></v-divider>
        <v-list-item
          v-for="(helpLink, index) in helpLinks"
          :key="index"
          link
          :href="helpLink.url"
          :title="helpLink.name"
          target="_blank"
        >
          <v-list-item-action>
            <v-icon>{{ helpLink.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ helpLink.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Link } from "@/models/Link";
import { MenuItem } from "@/models/MenuItem";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SideNavigation extends Vue {
  @Prop({ required: true })
  public topItems!: MenuItem[];

  @Prop({ required: false })
  public bottomItems?: MenuItem[];

  @Prop({ required: false })
  public helpLinks?: Link[];

  @Prop({ required: false, default: false })
  public display?: boolean;

  public onDisplayChanged(value: boolean) {
    this.$emit("change", value);
  }
  @Watch("$vuetify.breakpoint.smAndDown")
  private onBreakpointChanged(value: boolean) {
    if (value) this.$emit("change", false);
  }

  public areSettingsShown = false;
}
</script>
