import { ColumnSettingValue } from "@/models/ColumnSettingValue";
import { Extension } from "@/models/Extension";
import { ExtensionGroup } from "@/models/ExtensionGroup";
import { ExtensionGroupProxiesModel } from "@/models/ExtensionGroupProxiesModel";
import { MutationTree } from "vuex";
import { ExtensionGroupsState } from "./types";

export const mutations: MutationTree<ExtensionGroupsState> = {
  setExtensionGroups: (
    state: ExtensionGroupsState,
    extensionGroups: ExtensionGroup[]
  ) => (state.extensionGroups = extensionGroups),

  setSelectedExtensionGroup: (state: ExtensionGroupsState, group: Extension) =>
    (state.selectedExtensionGroup = group),

  updateSelectedExtensionGroupThreadCount: (
    state: ExtensionGroupsState,
    threadCount: number
  ) => {
    if (state.selectedExtensionGroup == null) return;

    state.selectedExtensionGroup.threadCount = threadCount;
  },

  updateSelectedExtensionGroupColumnSettings: (
    state: ExtensionGroupsState,
    columnSettings: ColumnSettingValue[]
  ) => {
    if (state.selectedExtensionGroup == null) return;

    state.selectedExtensionGroup.columnSettings = columnSettings;
  },

  /*PROXIES*/
  addProxiesToExtensionGroup: (
    state: ExtensionGroupsState,
    model: ExtensionGroupProxiesModel
  ) => {

    // TODO: PROBLEM state.extensionGroups.length HAS 0 LENGTH
    // console.log(`
    //   extensionGroupIds: ${model.extensionGroupIds.length},
    //   proxies: ${model.proxies.length}
    //   state: ${state.extensionGroups.length}
    // `)


    // model.extensionGroupIds.forEach(extensionGroupId => {
    //   const extensionGroup = state.extensionGroups.find(
    //     extensionGroup => {
    //       console.log(`${extensionGroup.id} == ${extensionGroupId}`)
    //       extensionGroup.id == extensionGroupId
    //     }
    //   )
    //   if(extensionGroup){
    //     extensionGroup.extensions.forEach(extension => extension.proxies = [...extension.proxies, ...model.proxies])
    //   }
    //   console.log('EXTENSION GROUP',extensionGroup)
    // })
  }
};
