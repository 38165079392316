import { MutationTree } from "vuex";
import { ScriptsState } from "./types";
import { Script } from "@/models/Script";
import { ScriptActionRow } from "@/models/ScriptActionRow";
import { Guid } from "guid-typescript";
import { SocialNetwork } from "@/models/enums/SocialNetwork";

export const mutations: MutationTree<ScriptsState> = {
  setScripts: (state: ScriptsState, scripts: Script[]) =>
    (state.scripts = scripts),
  addScript: (state: ScriptsState, script: Script) => {
    state.scripts.push(script);
    state.selectedScript = script;
  },
  updateScript: (state: ScriptsState, script: Script) => {
    const index: number = state.scripts.findIndex(s => s.id == script.id);
    if (index !== -1) {
      state.scripts.splice(index, 1, script);
    }
    if (typeof state.selectedScript === "object") {
      (state.selectedScript as Script).scriptActions = script.scriptActions;
    }
  },
  removeScript: (state: ScriptsState, id: Guid) => {
    const index: number = state.scripts.findIndex(s => s.id === id);
    if (index !== -1) {
      state.scripts.splice(index, 1);
    }
  },
  setActionRows: (state: ScriptsState, actionRows: ScriptActionRow[]) =>
    (state.actionRows = actionRows),
  removeActionRow: (state: ScriptsState, actionRow: ScriptActionRow) => {
    const index: number = state.actionRows.indexOf(actionRow);
    state.actionRows.splice(index, 1);
  },
  removeAllActionRows: (state: ScriptsState) => {
    state.actionRows = [];
  },
  addActionRow: (state: ScriptsState) =>
    state.actionRows.push({
      selectedActionId: null,
      selectedActionType: null,
      socialNetwork: null,
      actions: [],
      hasFixedOrder: false,
      timeLimit: 0
    }),
  setSelectedScript: (state: ScriptsState, script: Script | string | null) =>
    (state.selectedScript = script),
  setSelectedScriptSocialNetwork: (
    state: ScriptsState,
    socialNetwork: SocialNetwork | null
  ) => (state.selectedScriptSocialNetwork = socialNetwork)
};
