import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { BrowserProfilesState } from "./types";
import { RootState } from "../types";

export const state: BrowserProfilesState = {
  templates: [],
  defaultOptions: [],
  isDefaultOptionsLoading: false,
  adsGroups: []
};

const namespaced = false;

export const browserProfiles: Module<BrowserProfilesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
