<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script lang="ts">
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";

export default Vue.extend({
  name: "App",
  computed: {
    layout() {
      return this.$route?.meta?.layout || "default-layout";
    }
  },
  created: function() {
    if (localStorage.locale != null) this.$i18n.locale = localStorage.locale;
    axios.interceptors.response.use(undefined, function(err) {
      return new Promise(function() {
        if (
          err.response.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest
        ) {
          store.dispatch("logout");
          router.push({ path: "/login" }).catch(() => null);
        }
        throw err;
      });
    });
  }
});
</script>
