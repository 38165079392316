import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { ScheduleState } from "./types";
import { RootState } from "../types";

export const state: ScheduleState = {
  extensionIds: [],
  schedule: null
};

const namespaced = false;

export const documents: Module<ScheduleState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default { state, getters, actions, mutations };
