import Axios from "axios";
import { ActionTree } from "vuex";
import { BrowserConfigTemplatesState } from "./types";
import { RootState } from "../types";
import { Guid } from "guid-typescript";
import {
  CreateBrowserConfigTemplateModel,
  UpdateBrowserConfigTemplateModel
} from "@/models/BrowserConfigTemplate";

export const actions: ActionTree<BrowserConfigTemplatesState, RootState> = {
  fetchBrowserConfigTemplates({ commit, dispatch }) {
    return new Promise<void>(resolve => {
      Axios.get("/api/browserconfigtemplate")
        .then(response => {
          commit("setBrowserConfigTemplates", response.data.data);
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  createBrowserConfigTemplate(
    { commit, dispatch },
    model: CreateBrowserConfigTemplateModel
  ) {
    return new Promise<void>(resolve => {
      Axios.post("/api/browserconfigtemplate", model)
        .then(response =>
          commit("setBrowserConfigTemplate", response.data.data)
        )
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  updateBrowserConfigTemplate(
    { commit, dispatch },
    model: UpdateBrowserConfigTemplateModel
  ) {
    return new Promise<void>(resolve => {
      Axios.put("/api/browserconfigtemplate", model)
        .then(response => {
          commit("updateBrowserConfigTemplate", response.data.data);
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  deleteBrowserConfigTemplate({ commit, dispatch }, ids: Guid[]) {
    return new Promise<void>(resolve => {
      Axios.delete("/api/browserconfigtemplate", { data: { ids } })
        .then(response => {
          ids.forEach(id => commit("removeBrowserConfigTemplate", id));
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  }
};
