import { ActionTree } from "vuex";
import { AuthState } from "./types";
import { RootState } from "../types";
import { AuthModel } from "@/models/AuthModel";
import { AuthResponse } from "@/models/AuthResponse";
import Axios from "axios";
import { Guid } from "guid-typescript";

export const actions: ActionTree<AuthState, RootState> = {
  login({ commit }: any, model: AuthModel) {
    return new Promise((resolve, reject) => {
      // TODO: refactor it on backend
      const formData = new FormData();
      formData.append("username", model.username);
      formData.append("password", model.password);

      Axios.post<AuthResponse>("/token", formData)
        .then(response => {
          const token = response.data.accessToken;
          Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          localStorage.setItem("access-token", token);
          commit("authSuccess", token);
          resolve(token);
        })
        .catch(error => reject(error.response.data));
    });
  },
  activate({ commit }: any, id: Guid) {
    return new Promise<void>((resolve, reject) => {
      Axios.post("/api/registration/activate", { id: id.toString() })
        .then(() => {
          resolve();
        })
        .catch(error => reject(error.response.data));
    });
  },
  logout({ commit }: any) {
    return new Promise((resolve, reject) => {
      commit("authLogout");
      localStorage.removeItem("access-token");
      resolve("");
    });
  }
};
