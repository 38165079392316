import { ActionTree } from "vuex";
import { ExtensionsState } from "./types";
import { RootState } from "../types";
import { Extension } from "@/models/Extension";
import { GetExtensionsModel } from "@/models/GetExtensionsModel";
import Axios from "axios";
import { Guid } from "guid-typescript";
import { AddAccountModel } from "@/models/AddAccountModel";
import { UpdateBrowserConfigModel } from "@/models/UpdateBrowserConfigModel";
import { LoadAccountModel } from "@/models/LoadAccountModel";
import { SocialNetwork } from "@/models/enums/SocialNetwork";
import { ExtensionsProxiesModel } from "@/models/ExtensionsProxiesModel";
import { ExtensionProxiesModel } from "@/models/ExtensionProxiesModel";
import { BrowserConfig } from "@/models/BrowserConfig";
import { UpdateMultipleScriptsData } from "@/models/ResponseDataTypes/UpdateMultipleScriptsData";
import { Script } from "@/models/Script";

export const actions: ActionTree<ExtensionsState, RootState> = {
  fetchExtensions({ commit }: any, model: GetExtensionsModel) {
    return new Promise((resolve, reject) => {
      Axios.get("/api/extensions", { params: model })
        .then(response => {
          const extensions: Extension[] = response.data.data;
          const totalCount: number = response.data.totalCount;

          commit("setExtensions", extensions);
          commit("setTotalCount", totalCount);

          resolve(extensions);
        })
        .catch(error => reject(error.response.data));
    });
  },
  fetchExtension({ commit }: any, extensionId: Guid) {
    return new Promise((resolve, reject) => {
      Axios.get(`/api/extensions/${extensionId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  enableExtension({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/enable`)
        .then(response => {
          commit("updateExtension", response.data.data);
          resolve(response.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  enableExtensions({ commit, dispatch }, extensionIds: Extension["id"][]) {
    return new Promise<void>(resolve => {
      Axios.post(`/api/extensions/enable`, {
        extensionIds
      })
        .then(response => {
          const extensions: Extension[] = response.data.data;
          extensions.forEach(extension => commit("updateExtension", extension));
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  disableExtension({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/disable`)
        .then(response => {
          commit("updateExtension", response.data.data);
          resolve(response.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  disableExtensions({ commit, dispatch }, extensionIds: Extension["id"][]) {
    return new Promise<void>(resolve => {
      Axios.post(`/api/extensions/disable`, {
        extensionIds
      })
        .then(response => {
          const extensions: Extension[] = response.data.data;
          extensions.forEach(extension => commit("updateExtension", extension));
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  resetExtension({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/reset`)
        .then(response => {
          commit("updateExtension", response.data.data);
          resolve(response.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  resetExtensions({ commit, dispatch }, extensionIds: Extension["id"][]) {
    return new Promise<void>(resolve => {
      Axios.post(`/api/extensions/reset`, {
        extensionIds
      })
        .then(response => {
          const extensions: Extension[] = response.data.data;
          extensions.forEach(extension => commit("updateExtension", extension));
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  deleteExtension({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/extensions/${id}`)
        .then(response => {
          commit("removeExtension", id);
          resolve(response.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  deleteExtensions(
    { commit, dispatch },
    {
      extensionIds,
      isDeleteBrowserProfile
    }: {
      extensionIds: Extension["id"][];
      isDeleteBrowserProfile: boolean;
    }
  ) {
    return new Promise<void>(resolve => {
      commit("removeSelectedExtensions", extensionIds);
      Axios.post(`/api/extensions/delete-multiple`, {
        extensionIds,
        isDeleteBrowserProfile
      })
        .then(response => {
          const extensions: Extension[] = response.data.data;
          extensions.forEach(extension =>
            commit("removeExtension", extension.id)
          );
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  updateExtensionNote(
    { commit }: any,
    { id, note }: { id: Guid; note: string }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/extensions/${id}/note`, { note: note })
        .then(response => {
          commit("updateExtension", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  changeSocialNetwork(
    { commit }: any,
    { id, socialNetwork }: { id: Guid; socialNetwork: SocialNetwork }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/extensions/${id}/social-network`, {
        socialNetwork: socialNetwork
      })
        .then(response => {
          commit("updateExtension", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  changeSocialNetworks(
    { commit, dispatch },
    {
      extensionIds,
      socialNetwork
    }: { extensionIds: Extension["id"][]; socialNetwork: SocialNetwork }
  ) {
    return new Promise<void>(resolve => {
      Axios.put(`/api/extensions/social-network`, {
        extensionIds,
        socialNetwork
      })
        .then(response => {
          const extensions: Extension[] = response.data.data;
          extensions.forEach(extension => commit("updateExtension", extension));
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  enqueueScript({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/script/enqueue`)
        .then(response => {
          commit("updateExtensionScript", {
            id: id,
            script: response.data.data
          });
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  dequeueScript({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/script/dequeue`)
        .then(response => {
          commit("updateExtensionScript", {
            id: id,
            script: response.data.data
          });
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  startScript({ commit }, id: Guid) {
    return new Promise<void>((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/script/start`)
        .then(response => {
          commit("updateExtensionScript", {
            id: id,
            script: response.data.data
          });
          resolve();
        })
        .catch(error => reject(error.response.data));
    });
  },
  startScripts({ commit, dispatch }, ids: Extension["id"][]) {
    return new Promise<void>(resolve => {
      Axios.post(`/api/extensions/script/start`, { extensionIds: ids })
        .then(response => {
          const extensions: UpdateMultipleScriptsData = response.data.data;
          ids.forEach(extesnionId => {
            const extension = extensions.find(({ id }) => id === extesnionId);
            commit("updateExtensionScript", {
              id: extesnionId,
              script: extension?.script
            });
          });
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  stopScript({ commit }, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/script/stop`)
        .then(response => {
          commit("updateExtensionScript", {
            id: id,
            script: response.data.data
          });
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  stopScripts({ commit, dispatch }, ids: Extension["id"][]) {
    return new Promise<void>(resolve => {
      Axios.post(`/api/extensions/script/stop`, { extensionIds: ids })
        .then(response => {
          const extensions: UpdateMultipleScriptsData = response.data.data;
          ids.forEach(extesnionId => {
            const extension = extensions.find(({ id }) => id === extesnionId);
            commit("updateExtensionScript", {
              id: extesnionId,
              script: extension?.script
            });
          });
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  pauseScript({ commit }, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/script/pause`)
        .then(response => {
          commit("updateExtensionScript", {
            id: id,
            script: response.data.data
          });
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  pauseScripts({ commit, dispatch }, ids: Extension["id"][]) {
    return new Promise<void>(resolve => {
      Axios.post(`/api/extensions/script/pause`, { extensionIds: ids })
        .then(response => {
          const extensions: UpdateMultipleScriptsData = response.data.data;
          ids.forEach(extesnionId => {
            const extension = extensions.find(({ id }) => id === extesnionId);
            commit("updateExtensionScript", {
              id: extesnionId,
              script: extension?.script
            });
          });
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  restartScript({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/script/restart`)
        .then(response => {
          commit("updateExtensionScript", {
            id: id,
            script: response.data.data
          });
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  restartScripts({ commit, dispatch }, ids: Extension["id"][]) {
    return new Promise<void>(resolve => {
      Axios.post(`/api/extensions/script/restart`, { extensionIds: ids })
        .then(response => {
          const extensions: UpdateMultipleScriptsData = response.data.data;
          ids.forEach(extesnionId => {
            const extension = extensions.find(({ id }) => id === extesnionId);
            commit("updateExtensionScript", {
              id: extesnionId,
              script: extension?.script
            });
          });
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  changeScript(
    { commit }: any,
    { id, scriptId }: { id: Guid; scriptId: Guid }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/extensions/${id}/script`, { id: id, scriptId: scriptId })
        .then(response => {
          commit("updateExtensionScript", {
            id: id,
            script: response.data.data
          });
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  changeScripts(
    { commit, dispatch },
    {
      extensionIds,
      scriptId
    }: {
      extensionIds: Extension["id"][];
      scriptId: Script["id"];
    }
  ) {
    return new Promise<void>(resolve => {
      Axios.put(`/api/extensions/script`, { extensionIds, scriptId: scriptId })
        .then(response => {
          const extensions: UpdateMultipleScriptsData = response.data.data;
          extensionIds.forEach(extesnionId => {
            const extension = extensions.find(({ id }) => id === extesnionId);
            commit("updateExtensionScript", {
              id: extesnionId,
              script: extension?.script
            });
          });
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  getAccount({ commit }: any, extensionId: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.get(`/api/extensions/${extensionId}/account`)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data.message));
    });
  },
  addAccount({ commit }: any, model: AddAccountModel): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${model.extensionId}/account`, model)
        .then(response => {
          commit("updateExtensionAccount", {
            id: model.extensionId,
            account: response.data.data
          });
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  changeAccount({ commit }: any, model: AddAccountModel): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/extensions/${model.extensionId}/account`, model)
        .then(response => {
          commit("updateExtensionAccount", {
            id: model.extensionId,
            account: response.data.data
          });
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  reAuthorizeAccount({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/account/re-authorize`)
        .then(response => {
          commit("updateExtensionAccount", {
            id: id,
            account: response.data.data
          });
          resolve(response.data.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  downloadAccountCookies({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/account/download-cookies`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  getAccountCredentials({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${id}/account/get-credentials`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  deleteAccount({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/extensions/${id}/account`)
        .then(response => {
          commit("removeExtensionAccount", id);
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  updateBrowserConfig({ commit, dispatch }, model: UpdateBrowserConfigModel) {
    return new Promise<void>(resolve => {
      Axios.patch(`/api/extensions/browser-config`, model)
        .then(response => {
          const extensions: {
            id: Extension["id"];
            browserConfig: BrowserConfig;
          }[] = response.data.data;
          model.extensionIds.forEach(extensionId => {
            const newConfig = extensions.find(({ id }) => id === extensionId);
            commit("updateBrowserConfig", newConfig?.browserConfig);
          });
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  deleteBrowserConfig({ commit }: any, id: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/extensions/${id}/browser-config`)
        .then(response => {
          commit("removeBrowserConfig", id);
          resolve(response.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  deleteMultipleBrowserConfigs({ commit, dispatch }, ids: Extension["id"][]) {
    return new Promise<void>(resolve => {
      Axios.post(`/api/extensions/browser-config/delete-multiple`, {
        extensionIds: ids
      })
        .then(response => {
          ids.forEach(id => commit("removeBrowserConfig", id));
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  loadAccount(_, model: LoadAccountModel): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${model.extensionId}/account/load`, model)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  /* EXTENSION - PROXY */
  addProxiesToExtensions(
    { commit }: any,
    model: ExtensionsProxiesModel
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/addproxy`, {
        extensionGroupIds: [],
        extensionIds: model.extensionIds,
        proxyIds: model.proxies.map(proxy => proxy.id)
      })
        .then(response => {
          model.extensionIds.forEach(extensionId => {
            commit("addProxiesToExtension", {
              extensionId,
              proxies: model.proxies
            });
          });
          resolve(response.data);
        })
        .catch(e => reject(e));
    });
  },
  removeProxyFromExtension({ commit }: any, model: ExtensionProxiesModel) {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/extensions/${model.extensionId}/removeproxy`, {
        extensionId: model.extensionId,
        proxyIds: model.proxies.map(proxy => proxy.id)
      })
        .then(response => {
          commit("removeAllProxyExtension", model.extensionId);
          resolve(response.data);
        })
        .catch(e => reject(e));
    });
  },
  async updateProxiesExtension(
    { commit, dispatch }: any,
    model: ExtensionsProxiesModel
  ) {
    for (let i = 0; i < model.extensionIds.length; i++) {
      const extension = (
        await dispatch("fetchExtension", model.extensionIds[i])
      ).data.data;

      await dispatch("removeProxyFromExtension", {
        extensionId: extension.id,
        proxies: extension.proxies
      });
    }

    return dispatch("addProxiesToExtensions", model);
  },
  removeAllProxiesFromExtension(
    { dispatch, commit }: any,
    extensionId: Guid
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      dispatch("fetchExtension", extensionId)
        .then((response: any) => {
          const extension = response.data.data;

          dispatch("removeProxyFromExtension", {
            extensionId: extensionId,
            proxies: extension.proxies
          })
            .then((response: any) => {
              commit("removeAllProxyExtension", extensionId);
              resolve(response);
            })
            .catch((e: any) => reject(e));
        })
        .catch((e: any) => reject(e));
    });
  }
};
