import { GetterTree } from "vuex";
import { PreferencesState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<PreferencesState, RootState> = {
  allPreferences: (state: PreferencesState) => state.preferences,
  preferenceByName: (state: PreferencesState) => (name: string) =>
    state.preferences.find(p => p.preference.name === name),
  preferenceValueByName: (state: PreferencesState) => (name: string) =>
    state.preferences.find(p => p.preference.name === name)?.value
};
