import { GetterTree } from "vuex";
import { ActionsState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<ActionsState, RootState> = {
  allActions: (state: ActionsState) => state.actions,
  randomActions: (state: ActionsState, getters: any) =>
    state.actions.filter(a => a.actionType.isRandom && (a.actionType.socialNetwork === null || a.actionType.socialNetwork === getters.selectedSocialNetwork)),
  selectedSocialNetwork: (state: ActionsState) => state.selectedSocialNetwork,
  selectedActionType: (state: ActionsState) => state.selectedActionType,
  selectedAction: (state: ActionsState) => state.selectedAction,
  actionsByType: (state: ActionsState) => state.actionsByType,
  actionTypes: (state: ActionsState) => state.actionTypes,
  actionTypesBySocialNetwork: (state: ActionsState, getters: any) =>
    state.actionTypes.filter(a => a.socialNetwork === null || a.socialNetwork === getters.selectedSocialNetwork),
  actionTypesExcludeRandom: (state: ActionsState, getters: any) =>
    state.actionTypes.filter(a => !a.isRandom && (a.socialNetwork === null || a.socialNetwork === getters.selectedSocialNetwork)),
  randomActionRows: (state: ActionsState) => state.actionRows,
  actionOptions: (state: ActionsState) => state.actionOptions,
  actionOptionValues: (state: ActionsState) => state.actionOptionValues
};
