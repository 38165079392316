import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import store from "@/store";

@Component
export default class TitleMixin extends Vue {
  @Watch("pageTitle", { immediate: true })
  onTitleChanged(value: string) {
    if (value) document.title = `${value} | ${store.getters.appName}`;
  }
}
