import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { ProfileState } from "./types";
import { RootState } from "../types";

export const state: ProfileState = {
  profileInfo: null,
  tariffInfo: null
};

const namespaced = false;

export const documents: Module<ProfileState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default { state, getters, actions, mutations };
