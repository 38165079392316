<template>
  <v-banner class="pt-12" dark v-model="isBannerOpen" color="red" single-line>
    <v-row no-gutters>
      <v-col cols="2"></v-col>
      <v-col align="center">
        {{ message }}
      </v-col>
      <v-col cols="auto">
        <v-icon @click="closeBanner">
          mdi-close
        </v-icon>
      </v-col>
    </v-row>
  </v-banner>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class RefreshSubscriptionBanner extends Vue {
  private isBannerOpen = true;

  @Prop({ required: true })
  private timeDifference!: number;

  private get message() {
    if (this.timeDifference <= 0) {
      return this.$t("banners.refresh-subscribtion.subscription-expired");
    } else {
      return this.$t("banners.refresh-subscribtion.expiration-message");
    }
  }

  private closeBanner() {
    this.isBannerOpen = false;
  }
}
</script>
