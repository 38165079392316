import { ActionTree } from "vuex";
import { PaymentsState } from "./types";
import { RootState } from "../types";
import Axios from "axios";

import { PayProGlobalModel } from "@/models/PayProGlobalModel";
import { response } from "express";

export const actions: ActionTree<PaymentsState, RootState> = {
  createRobokassaPayment({ commit }: any, tariffId: number) {
    return new Promise((resolve, reject) => {
      Axios.post("/api/payments/robokassa/create", { tariffId: tariffId })
        .then(response => {
          if(response.data.succeeded)
            resolve(response.data.data.paymentLinkUrl);
          else
            reject(response.data.message)
        })
    });
  },

  createPayProGlobalPayment({ commit }: any, payInfo: PayProGlobalModel){
    return new Promise((resolve,reject) => {
      Axios.get("/api/payments/payproglobal/getpayprosubscriptions")
        .then(response => {
          const paymentTarrif = response.data.data.find((tariff:any) => tariff.id == payInfo.tarrifId)
          const paymentLink = paymentTarrif.subscriptionPackages.find((subscription:any) => subscription.isSubscription == payInfo.autoPayment) 
          resolve (paymentLink.paymentUrl)
        })
    })
  },

  terminateSubscriptionPayProGlobal(){
    return new Promise((resolve,reject) => {
      Axios.put("/api/payments/payproglobal/terminate").then(response => {  
        if(response.data.succeeded)
          resolve(response.data)
        else
          reject(response.data)
      })
    })
  }
};
