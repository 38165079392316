export enum ScriptStatus {
  Unknown = -1,
  Error,
  Pause,
  InProgress,
  Aborted,
  Finished,
  InQueue,
  WaitingForBrowser,
  FinishedWithErrors
}
