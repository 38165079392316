<template>
  <div class="locale-switcher">
    <v-select
      :value="$i18n.locale"
      :items="langs"
      hide-details
      dense
      solo
      style="max-width: 162px"
      item-text="caption"
      item-value="value"
      prepend-inner-icon="mdi-earth"
      @change="changeLanguage"
    ></v-select>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

@Component
export default class LocaleSwitcher extends Vue {
  @Prop({ required: true })
  private langs!: { value: string; caption: string }[];

  private changeLanguage(locale: string) {
    this.$i18n.locale = locale;
    localStorage.locale = locale;
    this.$vuetify.lang.current = locale;
  }
}
</script>
