import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { BrowserConfigTemplatesState } from "./types";
import { RootState } from "../types";

export const state: BrowserConfigTemplatesState = {
  templates: []
};

const namespaced = false;

export const browserConfigTemplates: Module<
  BrowserConfigTemplatesState,
  RootState
> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
