<template>
  <v-app class="auth-wrapper">
    <v-main>
      <slot />
    </v-main>
    <v-footer
      class="font-weight-medium justify-center white--text"
      color="transparent"
      inset
      app
    >
      <span class="caption">{{ $t("common.version") }} {{ appVersion }}</span>
    </v-footer>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class AuthLayout extends Vue {
  @Getter("appVersion")
  private appVersion!: string;
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(90, 92, 106, 1) 0%,
    rgba(32, 45, 58, 1) 81.3%
  );
}
</style>
