import { MutationTree } from "vuex";
import { BrowserProfilesState } from "./types";
import {
  BrowserProfileTemplate,
  DefaultBrowserProfileOption,
  AdsPowerGroupModel
} from "@/models/BrowserProfile";

export const mutations: MutationTree<BrowserProfilesState> = {
  setBrowserProfileTemplates: (state, templates: BrowserProfileTemplate[]) =>
    (state.templates = templates),
  setBrowserProfileTemplate: (state, template: BrowserProfileTemplate) => {
    state.templates.push(template);
  },
  removeBrowserProfileTemplate: (state, id: BrowserProfileTemplate["id"]) => {
    state.templates = state.templates.filter(template => !(template.id === id));
  },
  updateBrowserProfileTemplate: (
    state,
    updatedTemplate: BrowserProfileTemplate
  ) => {
    const updatedTemplateIndex = state.templates.findIndex(
      template => template.id === updatedTemplate.id
    );
    state.templates[updatedTemplateIndex] = updatedTemplate;
    state.templates = [...state.templates];
  },
  setBrowserProfileDefaultOptions: (
    state,
    options: DefaultBrowserProfileOption[]
  ) => (state.defaultOptions = options),
  setIsDefaultOptionsLoading: (state, value: boolean) => {
    state.isDefaultOptionsLoading = value;
  },
  setAdsPowerProfileGroups: (state, groups: AdsPowerGroupModel[]) =>
    (state.adsGroups = groups)
};
