import Axios from "axios";
import { ActionTree } from "vuex";
import { Guid } from "guid-typescript";
import { BrowserProfilesState } from "./types";
import { RootState } from "../types";
import {
  BrowserProfileTemplate,
  CreateBrowserProfileModel,
  CreateBrowserProfileResponse,
  CreateBrowserProfileTemplateModel,
  UpdateBrowserProfileTemplateModel
} from "@/models/BrowserProfile";
import { ErrorCode } from "@/models/enums/ErrorCode";

export const actions: ActionTree<BrowserProfilesState, RootState> = {
  fetchBrowserProfileTemplates({ commit, dispatch }) {
    return new Promise<void>(resolve => {
      Axios.get("/api/browserprofile/template")
        .then(response => {
          commit("setBrowserProfileTemplates", response.data.data);
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  createBrowserProfileTemplate(
    { commit, dispatch },
    model: CreateBrowserProfileTemplateModel
  ) {
    return new Promise<BrowserProfileTemplate | void>(resolve => {
      Axios.post("/api/browserprofile/template", model)
        .then(response => {
          if (!response.data.succeeded) {
            throw ErrorCode.UnknownError;
          }
          commit("setBrowserProfileTemplate", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          dispatch("showError", error);
          resolve();
        });
    });
  },
  updateBrowserProfileTemplate(
    { commit, dispatch },
    model: UpdateBrowserProfileTemplateModel
  ) {
    return new Promise<void>(resolve => {
      Axios.put("/api/browserprofile/template", model)
        .then(response => {
          commit("updateBrowserProfileTemplate", response.data.data);
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  deleteBrowserProfileTemplate({ commit, dispatch }, ids: Guid[]) {
    return new Promise<void>(resolve => {
      Axios.delete("/api/browserprofile/template", { data: { ids } })
        .then(response => {
          ids.forEach(id => commit("removeBrowserProfileTemplate", id));
          if (response.data.additionalData?.errorCode) {
            return Promise.reject(response.data.additionalData?.errorCode);
          }
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  createBrowserProfile({ dispatch }, model: CreateBrowserProfileModel) {
    return new Promise<CreateBrowserProfileResponse | void>(resolve => {
      Axios.post("/api/browserprofile/profile", model)
        .then(response => resolve(response.data.data))
        .catch(error => {
          dispatch("showError", error);
          resolve();
        });
    });
  },
  fetchDefaultBrowserProfileOptions({ commit, dispatch }) {
    return new Promise<void>(resolve => {
      Axios.get("/api/browserprofile/default-options")
        .then(response => {
          commit("setBrowserProfileDefaultOptions", response.data.data);
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  },
  fetchAdsPowerProfileGroups({ commit, dispatch }, templateId: Guid) {
    return new Promise<void>(resolve => {
      Axios.get(`/api/browserprofile/adspower/groups/${templateId}`)
        .then(response => {
          commit("setAdsPowerProfileGroups", response.data.data);
        })
        .catch(error => dispatch("showError", error))
        .finally(() => resolve());
    });
  }
};
